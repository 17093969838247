@font-face {
	font-family: 'Circular Std';
	src: url('../../assets/fonts/CircularStd-Medium.woff2') format('woff2'),
		url('../../assets/fonts/CircularStd-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/fonts/Nunito-ExtraLight.woff2') format('woff2'),
		url('../../assets/fonts/Nunito-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
/* utilities css */
.line-clamp-1 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.line-clamp-2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.line-clamp-3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.line-clamp-4 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
.text-black {
	color: #000000;
}
/* utilities css end */
/* navigation */

.restly-header-main-menu ul > li > a {
	color: #231f20;
}
.restly-header-main-menu ul > li .sub-menu li a {
	color: #231f20;
}
.restly-header-main-menu ul > li > a {
	color: #231f20;
}
.restly-header-main-menu ul > li .sub-menu li a {
	color: #231f20;
}
.restly-header-main-menu ul > li > a:hover {
	color: #801f82;
}
.restly-header-main-menu ul > li > a:hover {
	color: #9b2cfa;
}
.restly-header-main-menu ul > li > a {
	color: #231f20;
}
.restly-header-main-menu ul > li > a:hover {
	color: #2b70fa;
}
.restly-header-main-menu ul > li .sub-menu li a {
	color: #505056;
}
.header-nav-box.header-nav-box-7
	.restly-header-main-menu
	ul
	> li
	.sub-menu
	li
	a:hover {
	color: #2b70fa;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.restly-header-main-menu {
		display: none;
	}
}
@media (max-width: 767px) {
	.restly-header-main-menu {
		display: none;
	}
}
.restly-header-main-menu ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.restly-header-main-menu ul > li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.restly-header-main-menu ul > li {
		margin-right: 14px;
	}
}
.restly-header-main-menu ul > li > a {
	font-size: 15px;
	font-weight: 500;
	color: #0e1133;
	line-height: 45px;
}
.restly-header-main-menu ul > li > a i {
	padding-left: 6px;
}
.restly-header-main-menu ul > li .sub-menu {
	position: absolute;
	left: 0;
	top: 110%;
	width: auto;
	min-width: 220px;
	max-width: 220px;
	background-color: #fff;
	opacity: 0;
	visibility: hidden;
	transition: all linear 0.3s;
	z-index: 99;
	-webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	-moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	list-style-type: none;
	margin: 0;
	padding: 15px 0;
	border-radius: 5px;
	text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.restly-header-main-menu ul > li .sub-menu {
		min-width: 200px;
		max-width: 200px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.restly-header-main-menu ul > li .sub-menu {
		min-width: 200px;
		max-width: 200px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.restly-header-main-menu ul > li .sub-menu {
		position: relative;
		width: 100%;
		left: 0;
		top: auto;
		opacity: 1;
		visibility: visible;
		display: none;
		right: auto;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all none ease-out 0s;
		-moz-transition: all none ease-out 0s;
		-ms-transition: all none ease-out 0s;
		-o-transition: all none ease-out 0s;
		transition: all none ease-out 0s;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		text-align: left;
		border-top: 0;
		transition: 0s;
		padding: 0;
	}
}
@media (max-width: 767px) {
	.restly-header-main-menu ul > li .sub-menu {
		position: relative;
		width: 100%;
		left: 0;
		top: auto;
		opacity: 1;
		visibility: visible;
		display: none;
		right: auto;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all none ease-out 0s;
		-moz-transition: all none ease-out 0s;
		-ms-transition: all none ease-out 0s;
		-o-transition: all none ease-out 0s;
		transition: all none ease-out 0s;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		text-align: left;
		border-top: 0;
		transition: 0s;
		padding: 0;
	}
}
.restly-header-main-menu ul > li .sub-menu > li {
	position: relative;
	margin-left: 0;
	display: block;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
	margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
		margin-left: 0;
	}
}
@media (max-width: 767px) {
	.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
		margin-left: 0;
	}
}
.restly-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
	color: #0e1133;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.restly-header-main-menu ul > li .sub-menu > li a {
	display: block;
	padding: 0px 30px;
	position: relative;
	color: #505056;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	border-radius: 4px;
	margin: 0 0;
	line-height: 2.5;
	text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.restly-header-main-menu ul > li .sub-menu > li a {
		padding: 0 20px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.restly-header-main-menu ul > li .sub-menu > li a {
		padding: 0 20px;
	}
}
.restly-header-main-menu ul > li .sub-menu > li a i {
	float: right;
	font-size: 16px;
	margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.restly-header-main-menu ul > li .sub-menu > li a i {
		display: none;
	}
}
@media (max-width: 767px) {
	.restly-header-main-menu ul > li .sub-menu > li a i {
		display: none;
	}
}
.restly-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
	display: inline-block;
}
.restly-header-main-menu ul > li .sub-menu > li a:hover {
	padding-left: 35px;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
	right: auto;
	left: 100%;
	top: 50%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
		padding-left: 30px;
		-webkit-transition: all 0s ease-out 0s;
		-moz-transition: all 0s ease-out 0s;
		-ms-transition: all 0s ease-out 0s;
		-o-transition: all 0s ease-out 0s;
		transition: all 0s ease-out 0s;
	}
}
@media (max-width: 767px) {
	.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
		padding-left: 30px;
		-webkit-transition: all 0s ease-out 0s;
		-moz-transition: all 0s ease-out 0s;
		-ms-transition: all 0s ease-out 0s;
		-o-transition: all 0s ease-out 0s;
		transition: all 0s ease-out 0s;
	}
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li {
	position: relative;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
	right: auto;
	left: 100%;
	top: 50%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
	top: 0%;
	opacity: 1;
	visibility: visible;
}
.restly-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
	top: 0%;
	opacity: 1;
	visibility: visible;
}
.restly-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
	color: #2b70fa;
}
.restly-header-main-menu ul > li .sub-menu > li:hover > a {
	color: #2b70fa;
}
.restly-header-main-menu ul > li:hover .sub-menu {
	opacity: 1;
	visibility: visible;
	top: 100%;
}
.restly-header-8-area .header-nav-box .restly-header-main-menu ul > li a:hover {
	color: #db0f30;
}
@-webkit-keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}
@keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}
.restly-header-main-menu.restly-restly-header-two > ul > li {
	padding: 34px 25px;
}
.restly-header-main-menu.restly-restly-header-two ul li a {
	color: #f8f8f8;
}
.main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
	padding: 34px 25px;
}
.main-menu.navbar-expand-lg .restly-header-main-menu > ul > li > a {
	color: #231f20;
}

.main-menu.navbar-expand-lg
	.restly-header-main-menu.nav-home-five
	> ul
	> li
	> a {
	color: #0e1133;
}
.main-menu.navbar-expand-lg
	.restly-header-main-menu.nav-home-six
	> ul
	> li
	> a {
	color: #0e1133;
}
.main-menu.navbar-expand-lg .restly-header-main-menu.nav-home-six > ul > li {
	padding: 34px 20px;
}
.restly-header-main-menu ul > li > a {
	line-height: 0;
}
.restly-header-main-menu ul > li {
	margin-right: 0;
}
.nav-home-one.restly-header-main-menu ul > li {
	margin-right: 30px;
}
/* navigation end */

.news-section {
}
/* home one end */
/* home three */
.testimonial-wrap .slick-slide > div {
	margin: 0 15px;
}
.testimonial-item {
	margin: 0 0;
}
.news-section.news-section-three {
	background-image: none;
}
.footer-two.footer-home-three .footer-top {
	display: none;
}
/* home three end */

/* about two */
.about-section.r-com-about-section-two {
	background: none;
}
.about-section.r-com-about-section-two .section-title .sub-title {
	font-weight: 500;
	margin-bottom: 20px;
	color: #104cba;
	display: inline-block;
}
.about-section.r-com-about-section-two .about-content :is(span, p, h2, li) {
	color: #000000 !important;
}
/* about two end */
/* home 6 */
.testimonial-three-thumbs div > img {
	width: 70px !important;
	height: 70px !important;
	margin: 10px !important;
	cursor: pointer;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 50%;
	border: 2px solid transparent;
}
.testimonial-three-thumbs .slick-current div > img {
	border-color: #104cba !important;
}
.testimonial-three-thumbs.quomo-testimonial-three-thumbs {
	position: absolute;
	display: flex;
	justify-content: center;
	left: calc(50% - 130px);
}
.testimonial-three-thumbs {
	bottom: 135px;
}
/* home 6 end */
/* home 7  */
.header-seven .main-menu .restly-header-main-menu li a {
	font-size: 18px;
	font-weight: 500;
	font-family: 'Circular Std';
}
.header-seven .main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
	padding: 15px 15px;
}
.testimonial-four-active .slick-dots {
	position: unset;
}
.testimonial-four-author img {
	width: 110px;
	height: auto;
}
.testimonial-four-active .slick-slide > div {
	margin: 15px 15px;
}
.testimonial-four-item {
	margin: 0;
}
/* home 7 end */
.blog-four .slick-slide > div {
	margin: 15px 15px;
}
.blog-style-four {
	margin: 0;
}
.mobile-header {
	width: 100%;
	height: 60px;
	padding: 10px;
}
.mobile-header .logo-area img {
	width: 100px;
	height: auto;
}
.mobile-header .button-area span {
	color: #000000;
}
/* team page */
.team-section-three.team-page-three .section-title-with-btn {
	display: none;
}
/* team page end */
.header-seven .main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
	padding: 30px 15px;
}

/* ==================layout setting================== */
.layout-settings {
	width: 200px;
	height: 250px;
	position: fixed;
	right: -200px;
	top: calc(50vh - 150px);
	background: #f8f8f8;
	z-index: 999;
	box-shadow: 0px 0px 12px 0px #4c85f083;
	border-radius: 0 0 0 5px;
	transition: all 0.3s cubic-bezier(0.63, 0.27, 1, 0.41);
}
.layout-settings.active {
	right: 0;
}
.layout-settings .heading {
	padding: 20px 10px;
	border-bottom: 1px solid #4c85f083;
}
.layout-settings .heading h1 {
	font-size: 14px;
	margin: 0;
	line-height: 0;
	text-transform: uppercase;
}
.layout-settings .layout-content {
	padding: 10px 10px;
	height: calc(100% - 40px);
}
.layout-settings .layout-content ul li.content-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	direction: ltr;
}
.layout-settings .layout-content ul li.content-item .item-name span {
	font-size: 14px;
	letter-spacing: 1px;
}
.layout-settings .layout-content ul li.content-item .item-button {
	display: inline-flex;
	transition: all 1s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button button {
	width: 44px;
	height: 20px;
	background: #878f9a;
	border-radius: 23px;
	position: relative;
	transition: all 0.5s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button.active button {
	background: #27ae60;
	transition: all 0.5s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button button span {
	width: 17px;
	height: 18px;
	background: white;
	position: absolute;
	left: 1px;
	top: 1px;
	border-radius: 100%;
	transition: all 0.5s ease-in-out;
}
.layout-settings
	.layout-content
	ul
	li.content-item
	.item-button.active
	button
	span {
	left: 26px;
	transition: all 0.5s ease-in-out;
}
.layout-settings .close-btn-wrapper {
	position: absolute;
	width: 40px;
	height: 40px;
	left: -40px;
	top: 0;
	background: #104cba;
	border-radius: 5px 0 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.layout-settings .close-btn-wrapper span > svg {
	width: 30px;
	height: 30px;
	color: white;
	animation: rotedSettings 3s linear 0s infinite reverse;
}
@keyframes rotedSettings {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* =================================Home Eight==================================== */
.main-menu.navbar-expand-lg
	.r-header-eight.restly-header-main-menu
	> ul
	> li
	> a {
	color: black;
}
.services-eight
	.service-slider-wrap
	.slick-slider
	.slick-list
	.slick-track
	.slick-slide
	> div {
	margin: 0 15px;
}
.success-item.style-five span span:not(.count-text) {
	display: block;
	font-size: inherit;
}
.success-item .count-text span span {
	font-size: 48px;
}
.success-item .count-text.k:after {
	content: 'k';
	display: none;
}
.success-item .count-text.plus:after {
	content: '+';
	display: none;
}
.success-item .count-text.plus span:after {
	content: '+';
}
.success-item .count-text.k span:after {
	content: 'k';
}
.testimonial-five-wrap:before {
	z-index: 1;
}
.testimonial-five-wrap .slick-arrow {
	bottom: 38px;
}
/* =================================Home Eight end==================================== */
/* =================================Home nine end==================================== */
.home-nine .hero-content-eight h1 {
	font-size: 58px;
}
.home-nine
	:is(.actions-active, .sellers-active, .collection-category-active)
	.slick-dots {
	display: flex !important;
}
.home-nine
	:is(.actions-active, .sellers-active, .collection-category-active)
	.slick-dots
	li {
	margin: 0;
}
.home-nine
	:is(.actions-active, .sellers-active, .collection-category-active)
	.slick-dots
	li
	button {
	padding: 0;
}
.home-nine
	:is(.actions-active, .sellers-active, .collection-category-active)
	.slick-slide
	> div {
	margin-left: 15px;
	margin-right: 15px;
}
.home-nine
	:is(.actions-active, .sellers-active, .collection-category-active)
	.action-item {
	margin-left: 0;
	margin-right: 0;
}
.home-nine .collection-category-active .collection-category-item {
	margin-left: 0;
	margin-right: 0;
}

.slick-dots li button:before {
	content: '' !important;
}
.home-nine .offcanvas_menu_wrapper {
	background: #002b33;
}
.home-nine .offcanvas_main_menu li a {
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	display: block;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #ededed;
	color: #f8f8f8;
	transition: all 0.3s ease-out 0s;
}
.home-nine .layout-settings {
	background: #000000;
}

/* =================================Home nine end==================================== */

/* =================================rtl responsive==================================== */
@media only screen and (min-width: 1501px) {
	.direction-rtl .hero-six-images {
		margin-right: auto;
		margin-left: -175px;
	}
}
@media only screen and (min-width: 1501px) {
	.direction-rtl .gallery-three-wrap {
		left: auto;
		right: 20%;
	}
	.direction-rtl .hero-six-images {
		margin-right: 0;
		margin-left: -175px;
	}
	.direction-rtl .header-five .direction-rtl .header-top-wrap {
		margin-right: 0px;
		margin-left: 40px;
	}
	.direction-rtl .header-five .header-top {
		margin-right: 0;
		margin-left: -20px;
		padding-left: 0;
		padding-right: 20px;
	}
}
@media only screen and (max-width: 1500px) {
	.direction-rtl .top-right .office-time {
		margin-right: 0;
		margin-left: 15px;
	}
	.direction-rtl .top-left ul li {
		margin-left: 15px;
	}
	.direction-rtl .header-one .header-inner,
	.direction-rtl .header-one .header-top {
		padding-left: 85px;
	}
	.direction-rtl .header-two .header-inner {
		padding: 0 15px 0 30px;
	}
	.direction-rtl .header-two .header-top {
		padding-left: 30px;
	}
}
@media only screen and (max-width: 1199px) {
	.direction-rtl .header-one .header-inner,
	.direction-rtl .header-one .header-top {
		padding-right: 0;
		padding-left: 25px;
	}
	.direction-rtl .header-seven .main-menu {
		margin-right: 0px;
	}
	.direction-rtl .hero-eight-image {
		margin-left: 0;
	}
	.direction-rtl .success-item.style-five {
		padding-right: 0px;
		padding-left: 0;
		border-right: none;
		border-left: none;
	}
}
@media (min-width: 992px) {
	.direction-rtl .text-lg-right {
		text-align: left !important;
	}
}

@media only screen and (max-width: 991px) {
	.direction-rtl .main-menu .navbar-collapse li {
		float: none;
	}
	.direction-rtl .header-eight .header-top {
		padding-right: 0;
	}
	.direction-rtl
		.header-eight
		.main-menu
		.navbar-collapse
		.navigation
		> li
		> .dropdown-btn {
		margin-left: 0px;
		margin-right: 3px;
	}
	.direction-rtl .protect-left-part {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.header-three .top-right {
		margin-left: auto;
	}
}
@media only screen and (max-width: 575px) {
	.direction-rtl .testimonial-five-wrap .slick-arrow.prev {
		left: 75px;
	}
	.direction-rtl .testimonial-five-wrap .slick-arrow {
		left: 25px;
	}
}
@media only screen and (max-width: 480px) {
	.direction-rtl .about-eight-images img:first-child {
		margin-left: 0px;
	}
}
