/*----------------------------------------------------------------------
	CSS INDEX
	----------------------

    01. Default Style
    02. Common Classes
    03. Repeat Style
    04. Padding Margin
    05. Custom Animation
    06. Header style
    07. Hero Section
    08. Page Banner
    09. About Section
    10. Services
    11. Fact Counter
    12. Feature
    13. Work Progress
    14. Pricing
    15. Gallery
    16. News
    17. Widgets
    18. Client Logo
    19. Testimonials
    20. Team Members
    21. Subscribe
    22. Call To Action
    23. Contact
    24. Shop Page
    25. FAQs
    26. 404 Error
    27. Main Slider
    28. Protects Section
    29. Actions Section
    30. Sellers Section
    31. Main Footer */
/* -------------------------------------------------------------- */
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700;800&family=Rubik:wght@400;500&display=swap');
/*******************************************************/
/******************* 01. Default Style *****************/
/*******************************************************/
* {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

body {
	color: #454545;
	background: #f8f8f8;
	font-weight: 400;
	line-height: 28px;
	font-size: 16px;
	font-family: 'Rubik', sans-serif;
}

a {
	color: #454545;
	cursor: pointer;
	outline: none;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	text-decoration: none;
}
a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 12px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #111111;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white h1 a,
.text-white h2 a,
.text-white h3 a,
.text-white h4 a,
.text-white h5 a,
.text-white h6 a {
	color: #f8f8f8;
}

.text-black h1,
.text-black h2,
.text-black h3,
.text-black h4,
.text-black h5,
.text-black h6,
.text-black h1 a,
.text-black h2 a,
.text-black h3 a,
.text-black h4 a,
.text-black h5 a,
.text-black h6 a {
	color: #231f20;
}

h1 {
	font-size: 72px;
}

h2 {
	line-height: 1.33;
	font-size: 36px;
}

h3 {
	line-height: 1.55;
	font-size: 22px;
}

h4 {
	line-height: 1.4;
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	color: #454545;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
	display: inline-block;
}

header:after,
section:after,
footer:after {
	display: block;
	clear: both;
	content: '';
}

/*======= Input Styles =======*/
input,
select,
textarea,
.form-control {
	width: 100%;
	height: auto;
	padding: 16px 30px;
	border-radius: 5px;
	background-color: #f8f8f8;
	border: 2px solid #f0f0f0;
}

textarea {
	display: inherit;
	padding-top: 20px;
}

label {
	cursor: pointer;
	font-weight: 500;
	margin-bottom: 5px;
	color: #111111;
}

.form-group {
	margin-bottom: 25px;
}

input:focus,
button:focus,
.form-control:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #c1c1c1;
}

input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

input[type='checkbox'],
input[type='radio'] {
	height: auto;
	width: auto;
}

.text-white input,
.text-white select,
.text-white textarea,
.text-white .form-control {
	color: #f8f8f850;
	border-color: #a17f3a;
	background: #a46e00;
}

.text-white label {
	margin-bottom: 10px;
}

/*******************************************************/
/*****************  02. Common Classes *****************/
/*******************************************************/
.page-wrapper {
	position: relative;
	z-index: 9;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	min-width: 300px;
	background: #f8f8f8;
}

.container {
	max-width: 1200px;
}

/** Section Title style **/
.section-title .sub-title {
	font-weight: 500;
	margin-bottom: 20px;
	color: #ffb81a;
	display: inline-block;
}

.text-white .sub-title {
	color: #f8f8f8;
}

.section-title-with-btn {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

/** Button style **/
.theme-btn,
a.theme-btn {
	background: #ffb81a;
	font-size: 16px;
	color: #231f20;
	cursor: pointer;
	font-weight: 500;
	text-align: center;
	border-radius: 5px;
	padding: 11px 28px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	text-transform: capitalize;
}

.theme-btn.btn-circle,
a.theme-btn.btn-circle {
	border-radius: 50px;
	padding-left: 35px;
	padding-right: 35px;
}

.theme-btn:hover,
a.theme-btn:hover {
	color: #f8f8f8;
	background: #775001;
}
.theme-btn:hover i,
a.theme-btn:hover i {
	margin-left: 15px;
	margin-right: -5px;
}

.theme-btn.style-a,
a.theme-btn.style-a {
	padding: 11px 40px;
	color: #a76f00;
	background: #f0deba;
}
.theme-btn.style-a:hover,
a.theme-btn.style-a:hover {
	color: #a76f00;
	background: #ffb81a;
}

.theme-btn.style-b,
a.theme-btn.style-b {
	padding: 10px 35px;
	background: transparent;
	border: 1px solid #f8f8f8;
}
.theme-btn.style-b:hover,
a.theme-btn.style-b:hover {
	background: #f8f8f8;
	color: #ffb81a;
}

@media only screen and (max-width: 480px) {
	.theme-btn,
	a.theme-btn {
		font-size: 14px;
		padding: 12px 25px;
	}
}

/** List style **/
.list-style-a li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 500;
	margin-top: 20px;
}
.list-style-a li:before {
	color: #ffb81a;
	font-size: 14px;
	content: '\f00c';
	font-weight: 600;
	margin-top: -4px;
	margin-right: 15px;
	width: 35px;
	height: 35px;
	background: #231f20;
	line-height: 35px;
	border-radius: 50%;
	text-align: center;
	font-family: 'Font Awesome 5 Free';
}

.list-style-b li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	text-transform: capitalize;
}

.list-style-b i {
	margin: 6px 20px 0 0;
}

.list-style-c li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 7px;
	text-transform: capitalize;
}
.list-style-c li:before {
	font-size: 14px;
	content: '\f00c';
	font-weight: 700;
	margin-right: 20px;
	color: #ffb81a;
	font-family: 'Font Awesome 5 Free';
}

/** Social Link Style One **/
.social-style-a {
	display: inline-block;
}
.social-style-a a {
	margin-right: 15px;
	color: #111111;
}
.social-style-a a:last-child {
	margin-right: 0;
}
.social-style-a a:hover {
	color: #ffb81a;
}

/** Social Link Style two **/
.social-style-b {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.social-style-b a {
	color: #f8f8f8;
	opacity: 0.4;
	margin-right: 7px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
}
.social-style-b a:last-child {
	margin-right: 0;
}
.social-style-b a:hover {
	opacity: 1;
	color: #fdd179;
}

/*** Preloader style ***/
.preloader {
	position: fixed;
	width: 100%;
	height: 100vh;
	background: #f8f8f8;
	z-index: 9999999;
}
.preloader .theme-loader {
	margin: auto;
	height: 70px;
	width: 70px;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	position: absolute;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-animation: animate 4.59s ease-in-out infinite;
	animation: animate 4.59s ease-in-out infinite;
}
.preloader .theme-loader:before,
.preloader .theme-loader:after {
	content: '';
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: absolute;
	border: 15px solid #fdd179;
	animation: animate_1 1.73s ease-in-out infinite both reverse;
}
.preloader .theme-loader:after {
	border-color: #ffb81a;
	-webkit-animation-delay: -0.86s;
	animation-delay: -0.86s;
}

/* Pagination */
.pagination li {
	margin: 0 10px 10px 0;
}
.pagination li a,
.pagination li .page-link {
	padding: 0;
	color: #d5bb85;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 60px;
	height: 60px;
	background: #f8f8f8;
	line-height: 60px;
	border-radius: 50%;
	text-align: center;
	border: 1px solid #e5e5e5;
}
@media only screen and (max-width: 480px) {
	.pagination li a,
	.pagination li .page-link {
		width: 45px;
		height: 45px;
		line-height: 45px;
	}
}
.pagination li.disabled .page-link {
	border-radius: 50%;
	color: #aaaaaa;
	background: #eaeaea;
	border-color: #aaaaaa;
}
.pagination li:hover:not(.disabled) .page-link,
.pagination li.active .page-link {
	color: #f8f8f8;
	background: #ffb81a;
	border-color: #bc7e02;
}

/*** Scroll Top style ***/
.scroll-top {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 99;
	width: 40px;
	height: 40px;
	color: #111111;
	display: none;
	cursor: pointer;
	border-radius: 5px;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	background: #ffb81a;
}

/* Text White */
.text-white *,
.text-white a {
	color: #f8f8f8;
}

/* Overlay */
.overlay {
	z-index: 1;
	position: relative;
}
.overlay::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.4;
	background-color: #312201;
}

/* Video Play Btn */
.video-play {
	display: inline-block;
}
.video-play i {
	width: 55px;
	height: 55px;
	background: #111111;
	line-height: 55px;
	border-radius: 50%;
	text-align: center;
	color: #ffb81a;
}
.video-play span {
	font-size: 18px;
	font-weight: 500;
	margin-left: 10px;
}

/* Position */
.rel {
	position: relative;
}

.z-0 {
	z-index: 0;
}

.z-1 {
	z-index: 1;
}

.z-2 {
	z-index: 2;
}

.z-3 {
	z-index: 3;
}

.z-4 {
	z-index: 4;
}

.z-5 {
	z-index: 5;
}

/* Backgruond Size */
.bgs-cover {
	background-size: cover;
	background-position: center;
}

/* Border None */
.no-border {
	border: none !important;
}

.box-shadow {
	-webkit-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
}

/* Background Colors */

.bg-black {
	background-color: #231f20;
}

.bg-yellow {
	background-color: #ffb81a;
}

.bg-dark-blue {
	background-color: #614508;
}

.bg-lighter {
	background-color: #f0f0f0;
}

/* Border Radius */
.br-5 {
	border-radius: 5px;
}

.br-10 {
	border-radius: 10px;
}

.br-15 {
	border-radius: 15px;
}

.br-20 {
	border-radius: 20px;
}

.br-25 {
	border-radius: 25px;
}

.br-30 {
	border-radius: 30px;
}

/*******************************************************/
/******************* 03. Repeat Style ******************/
/*******************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.success-item .count-text,
.pricing-header .price {
	font-weight: 800;
	color: #111111;
	font-family: 'Nunito', sans-serif;
}

.service-normal .icon i,
.feature-item .icon i,
.work-progress-item .icon i {
	font-size: 70px;
	display: inline-block;
	color: #ffb81a;
	margin-bottom: 20px;
}

/*******************************************************/
/************** 04. Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px;
}

.p-15 {
	padding: 15px;
}

.p-20 {
	padding: 20px;
}

.p-25 {
	padding: 25px;
}

.p-30 {
	padding: 30px;
}

.p-35 {
	padding: 35px;
}

.p-40 {
	padding: 40px;
}

.p-45 {
	padding: 45px;
}

.p-50 {
	padding: 50px;
}

.p-55 {
	padding: 55px;
}

.p-60 {
	padding: 60px;
}

.p-65 {
	padding: 65px;
}

.p-70 {
	padding: 70px;
}

.p-75 {
	padding: 75px;
}

.p-80 {
	padding: 80px;
}

.p-85 {
	padding: 85px;
}

.p-90 {
	padding: 90px;
}

.p-95 {
	padding: 95px;
}

.p-100 {
	padding: 100px;
}

.p-105 {
	padding: 105px;
}

.p-110 {
	padding: 110px;
}

.p-115 {
	padding: 115px;
}

.p-120 {
	padding: 120px;
}

.p-125 {
	padding: 125px;
}

.p-130 {
	padding: 130px;
}

.p-135 {
	padding: 135px;
}

.p-140 {
	padding: 140px;
}

.p-145 {
	padding: 145px;
}

.p-150 {
	padding: 150px;
}

.p-155 {
	padding: 155px;
}

.p-160 {
	padding: 160px;
}

.p-165 {
	padding: 165px;
}

.p-170 {
	padding: 170px;
}

.p-175 {
	padding: 175px;
}

.p-180 {
	padding: 180px;
}

.p-185 {
	padding: 185px;
}

.p-190 {
	padding: 190px;
}

.p-195 {
	padding: 195px;
}

.p-200 {
	padding: 200px;
}

.p-205 {
	padding: 205px;
}

.p-210 {
	padding: 210px;
}

.p-215 {
	padding: 215px;
}

.p-220 {
	padding: 220px;
}

.p-225 {
	padding: 225px;
}

.p-230 {
	padding: 230px;
}

.p-235 {
	padding: 235px;
}

.p-240 {
	padding: 240px;
}

.p-245 {
	padding: 245px;
}

.p-250 {
	padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
	padding-top: 5px !important;
}

.pt-10,
.py-10 {
	padding-top: 10px;
}

.pt-15,
.py-15 {
	padding-top: 15px;
}

.pt-20,
.py-20 {
	padding-top: 20px;
}

.pt-25,
.py-25 {
	padding-top: 25px;
}

.pt-30,
.py-30 {
	padding-top: 30px;
}

.pt-35,
.py-35 {
	padding-top: 35px;
}

.pt-40,
.py-40 {
	padding-top: 40px;
}

.pt-45,
.py-45 {
	padding-top: 45px;
}

.pt-50,
.py-50 {
	padding-top: 50px;
}

.pt-55,
.py-55 {
	padding-top: 55px;
}

.pt-60,
.py-60 {
	padding-top: 60px;
}

.pt-65,
.py-65 {
	padding-top: 65px;
}

.pt-70,
.py-70 {
	padding-top: 70px;
}

.pt-75,
.py-75 {
	padding-top: 75px;
}

.pt-80,
.py-80 {
	padding-top: 80px;
}

.pt-85,
.py-85 {
	padding-top: 85px;
}

.pt-90,
.py-90 {
	padding-top: 90px;
}

.pt-95,
.py-95 {
	padding-top: 95px;
}

.pt-100,
.py-100 {
	padding-top: 100px;
}

.pt-105,
.py-105 {
	padding-top: 105px;
}

.pt-110,
.py-110 {
	padding-top: 110px;
}

.pt-115,
.py-115 {
	padding-top: 115px;
}

.pt-120,
.py-120 {
	padding-top: 120px;
}

.pt-125,
.py-125 {
	padding-top: 125px;
}

.pt-130,
.py-130 {
	padding-top: 130px;
}

.pt-135,
.py-135 {
	padding-top: 135px;
}

.pt-140,
.py-140 {
	padding-top: 140px;
}

.pt-145,
.py-145 {
	padding-top: 145px;
}

.pt-150,
.py-150 {
	padding-top: 150px;
}

.pt-155,
.py-155 {
	padding-top: 155px;
}

.pt-160,
.py-160 {
	padding-top: 160px;
}

.pt-165,
.py-165 {
	padding-top: 165px;
}

.pt-170,
.py-170 {
	padding-top: 170px;
}

.pt-175,
.py-175 {
	padding-top: 175px;
}

.pt-180,
.py-180 {
	padding-top: 180px;
}

.pt-185,
.py-185 {
	padding-top: 185px;
}

.pt-190,
.py-190 {
	padding-top: 190px;
}

.pt-195,
.py-195 {
	padding-top: 195px;
}

.pt-200,
.py-200 {
	padding-top: 200px;
}

.pt-205,
.py-205 {
	padding-top: 205px;
}

.pt-210,
.py-210 {
	padding-top: 210px;
}

.pt-215,
.py-215 {
	padding-top: 215px;
}

.pt-220,
.py-220 {
	padding-top: 220px;
}

.pt-225,
.py-225 {
	padding-top: 225px;
}

.pt-230,
.py-230 {
	padding-top: 230px;
}

.pt-235,
.py-235 {
	padding-top: 235px;
}

.pt-240,
.py-240 {
	padding-top: 240px;
}

.pt-245,
.py-245 {
	padding-top: 245px;
}

.pt-250,
.py-250 {
	padding-top: 250px;
}

/* Padding Right */
.pr-5,
.px-5 {
	padding-right: 5px !important;
}

.pr-10,
.px-10 {
	padding-right: 10px;
}

.pr-15,
.px-15 {
	padding-right: 15px;
}

.pr-20,
.px-20 {
	padding-right: 20px;
}

.pr-25,
.px-25 {
	padding-right: 25px;
}

.pr-30,
.px-30 {
	padding-right: 30px;
}

.pr-35,
.px-35 {
	padding-right: 35px;
}

.pr-40,
.px-40 {
	padding-right: 40px;
}

.pr-45,
.px-45 {
	padding-right: 45px;
}

.pr-50,
.px-50 {
	padding-right: 50px;
}

.pr-55,
.px-55 {
	padding-right: 55px;
}

.pr-60,
.px-60 {
	padding-right: 60px;
}

.pr-65,
.px-65 {
	padding-right: 65px;
}

.pr-70,
.px-70 {
	padding-right: 70px;
}

.pr-75,
.px-75 {
	padding-right: 75px;
}

.pr-80,
.px-80 {
	padding-right: 80px;
}

.pr-85,
.px-85 {
	padding-right: 85px;
}

.pr-90,
.px-90 {
	padding-right: 90px;
}

.pr-95,
.px-95 {
	padding-right: 95px;
}

.pr-100,
.px-100 {
	padding-right: 100px;
}

.pr-105,
.px-105 {
	padding-right: 105px;
}

.pr-110,
.px-110 {
	padding-right: 110px;
}

.pr-115,
.px-115 {
	padding-right: 115px;
}

.pr-120,
.px-120 {
	padding-right: 120px;
}

.pr-125,
.px-125 {
	padding-right: 125px;
}

.pr-130,
.px-130 {
	padding-right: 130px;
}

.pr-135,
.px-135 {
	padding-right: 135px;
}

.pr-140,
.px-140 {
	padding-right: 140px;
}

.pr-145,
.px-145 {
	padding-right: 145px;
}

.pr-150,
.px-150 {
	padding-right: 150px;
}

.pr-155,
.px-155 {
	padding-right: 155px;
}

.pr-160,
.px-160 {
	padding-right: 160px;
}

.pr-165,
.px-165 {
	padding-right: 165px;
}

.pr-170,
.px-170 {
	padding-right: 170px;
}

.pr-175,
.px-175 {
	padding-right: 175px;
}

.pr-180,
.px-180 {
	padding-right: 180px;
}

.pr-185,
.px-185 {
	padding-right: 185px;
}

.pr-190,
.px-190 {
	padding-right: 190px;
}

.pr-195,
.px-195 {
	padding-right: 195px;
}

.pr-200,
.px-200 {
	padding-right: 200px;
}

.pr-205,
.px-205 {
	padding-right: 205px;
}

.pr-210,
.px-210 {
	padding-right: 210px;
}

.pr-215,
.px-215 {
	padding-right: 215px;
}

.pr-220,
.px-220 {
	padding-right: 220px;
}

.pr-225,
.px-225 {
	padding-right: 225px;
}

.pr-230,
.px-230 {
	padding-right: 230px;
}

.pr-235,
.px-235 {
	padding-right: 235px;
}

.pr-240,
.px-240 {
	padding-right: 240px;
}

.pr-245,
.px-245 {
	padding-right: 245px;
}

.pr-250,
.px-250 {
	padding-right: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
	padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
	padding-bottom: 10px;
}

.pb-15,
.py-15 {
	padding-bottom: 15px;
}

.pb-20,
.py-20 {
	padding-bottom: 20px;
}

.pb-25,
.py-25 {
	padding-bottom: 25px;
}

.pb-30,
.py-30 {
	padding-bottom: 30px;
}

.pb-35,
.py-35 {
	padding-bottom: 35px;
}

.pb-40,
.py-40 {
	padding-bottom: 40px;
}

.pb-45,
.py-45 {
	padding-bottom: 45px;
}

.pb-50,
.py-50 {
	padding-bottom: 50px;
}

.pb-55,
.py-55 {
	padding-bottom: 55px;
}

.pb-60,
.py-60 {
	padding-bottom: 60px;
}

.pb-65,
.py-65 {
	padding-bottom: 65px;
}

.pb-70,
.py-70 {
	padding-bottom: 70px;
}

.pb-75,
.py-75 {
	padding-bottom: 75px;
}

.pb-80,
.py-80 {
	padding-bottom: 80px;
}

.pb-85,
.py-85 {
	padding-bottom: 85px;
}

.pb-90,
.py-90 {
	padding-bottom: 90px;
}

.pb-95,
.py-95 {
	padding-bottom: 95px;
}

.pb-100,
.py-100 {
	padding-bottom: 100px;
}

.pb-105,
.py-105 {
	padding-bottom: 105px;
}

.pb-110,
.py-110 {
	padding-bottom: 110px;
}

.pb-115,
.py-115 {
	padding-bottom: 115px;
}

.pb-120,
.py-120 {
	padding-bottom: 120px;
}

.pb-125,
.py-125 {
	padding-bottom: 125px;
}

.pb-130,
.py-130 {
	padding-bottom: 130px;
}

.pb-135,
.py-135 {
	padding-bottom: 135px;
}

.pb-140,
.py-140 {
	padding-bottom: 140px;
}

.pb-145,
.py-145 {
	padding-bottom: 145px;
}

.pb-150,
.py-150 {
	padding-bottom: 150px;
}

.pb-155,
.py-155 {
	padding-bottom: 155px;
}

.pb-160,
.py-160 {
	padding-bottom: 160px;
}

.pb-165,
.py-165 {
	padding-bottom: 165px;
}

.pb-170,
.py-170 {
	padding-bottom: 170px;
}

.pb-175,
.py-175 {
	padding-bottom: 175px;
}

.pb-180,
.py-180 {
	padding-bottom: 180px;
}

.pb-185,
.py-185 {
	padding-bottom: 185px;
}

.pb-190,
.py-190 {
	padding-bottom: 190px;
}

.pb-195,
.py-195 {
	padding-bottom: 195px;
}

.pb-200,
.py-200 {
	padding-bottom: 200px;
}

.pb-205,
.py-205 {
	padding-bottom: 205px;
}

.pb-210,
.py-210 {
	padding-bottom: 210px;
}

.pb-215,
.py-215 {
	padding-bottom: 215px;
}

.pb-220,
.py-220 {
	padding-bottom: 220px;
}

.pb-225,
.py-225 {
	padding-bottom: 225px;
}

.pb-230,
.py-230 {
	padding-bottom: 230px;
}

.pb-235,
.py-235 {
	padding-bottom: 235px;
}

.pb-240,
.py-240 {
	padding-bottom: 240px;
}

.pb-245,
.py-245 {
	padding-bottom: 245px;
}

.pb-250,
.py-250 {
	padding-bottom: 250px;
}

/* Padding Left */
.pl-5,
.px-5 {
	padding-left: 5px !important;
}

.pl-10,
.px-10 {
	padding-left: 10px;
}

.pl-15,
.px-15 {
	padding-left: 15px;
}

.pl-20,
.px-20 {
	padding-left: 20px;
}

.pl-25,
.px-25 {
	padding-left: 25px;
}

.pl-30,
.px-30 {
	padding-left: 30px;
}

.pl-35,
.px-35 {
	padding-left: 35px;
}

.pl-40,
.px-40 {
	padding-left: 40px;
}

.pl-45,
.px-45 {
	padding-left: 45px;
}

.pl-50,
.px-50 {
	padding-left: 50px;
}

.pl-55,
.px-55 {
	padding-left: 55px;
}

.pl-60,
.px-60 {
	padding-left: 60px;
}

.pl-65,
.px-65 {
	padding-left: 65px;
}

.pl-70,
.px-70 {
	padding-left: 70px;
}

.pl-75,
.px-75 {
	padding-left: 75px;
}

.pl-80,
.px-80 {
	padding-left: 80px;
}

.pl-85,
.px-85 {
	padding-left: 85px;
}

.pl-90,
.px-90 {
	padding-left: 90px;
}

.pl-95,
.px-95 {
	padding-left: 95px;
}

.pl-100,
.px-100 {
	padding-left: 100px;
}

.pl-105,
.px-105 {
	padding-left: 105px;
}

.pl-110,
.px-110 {
	padding-left: 110px;
}

.pl-115,
.px-115 {
	padding-left: 115px;
}

.pl-120,
.px-120 {
	padding-left: 120px;
}

.pl-125,
.px-125 {
	padding-left: 125px;
}

.pl-130,
.px-130 {
	padding-left: 130px;
}

.pl-135,
.px-135 {
	padding-left: 135px;
}

.pl-140,
.px-140 {
	padding-left: 140px;
}

.pl-145,
.px-145 {
	padding-left: 145px;
}

.pl-150,
.px-150 {
	padding-left: 150px;
}

.pl-155,
.px-155 {
	padding-left: 155px;
}

.pl-160,
.px-160 {
	padding-left: 160px;
}

.pl-165,
.px-165 {
	padding-left: 165px;
}

.pl-170,
.px-170 {
	padding-left: 170px;
}

.pl-175,
.px-175 {
	padding-left: 175px;
}

.pl-180,
.px-180 {
	padding-left: 180px;
}

.pl-185,
.px-185 {
	padding-left: 185px;
}

.pl-190,
.px-190 {
	padding-left: 190px;
}

.pl-195,
.px-195 {
	padding-left: 195px;
}

.pl-200,
.px-200 {
	padding-left: 200px;
}

.pl-205,
.px-205 {
	padding-left: 205px;
}

.pl-210,
.px-210 {
	padding-left: 210px;
}

.pl-215,
.px-215 {
	padding-left: 215px;
}

.pl-220,
.px-220 {
	padding-left: 220px;
}

.pl-225,
.px-225 {
	padding-left: 225px;
}

.pl-230,
.px-230 {
	padding-left: 230px;
}

.pl-235,
.px-235 {
	padding-left: 235px;
}

.pl-240,
.px-240 {
	padding-left: 240px;
}

.pl-245,
.px-245 {
	padding-left: 245px;
}

.pl-250,
.px-250 {
	padding-left: 250px;
}

/* Margin Around */
.m-5 {
	margin: 5px !important;
}

.m-10 {
	margin: 10px;
}

.m-15 {
	margin: 15px;
}

.m-20 {
	margin: 20px;
}

.m-25 {
	margin: 25px;
}

.m-30 {
	margin: 30px;
}

.m-35 {
	margin: 35px;
}

.m-40 {
	margin: 40px;
}

.m-45 {
	margin: 45px;
}

.m-50 {
	margin: 50px;
}

.m-55 {
	margin: 55px;
}

.m-60 {
	margin: 60px;
}

.m-65 {
	margin: 65px;
}

.m-70 {
	margin: 70px;
}

.m-75 {
	margin: 75px;
}

.m-80 {
	margin: 80px;
}

.m-85 {
	margin: 85px;
}

.m-90 {
	margin: 90px;
}

.m-95 {
	margin: 95px;
}

.m-100 {
	margin: 100px;
}

.m-105 {
	margin: 105px;
}

.m-110 {
	margin: 110px;
}

.m-115 {
	margin: 115px;
}

.m-120 {
	margin: 120px;
}

.m-125 {
	margin: 125px;
}

.m-130 {
	margin: 130px;
}

.m-135 {
	margin: 135px;
}

.m-140 {
	margin: 140px;
}

.m-145 {
	margin: 145px;
}

.m-150 {
	margin: 150px;
}

.m-155 {
	margin: 155px;
}

.m-160 {
	margin: 160px;
}

.m-165 {
	margin: 165px;
}

.m-170 {
	margin: 170px;
}

.m-175 {
	margin: 175px;
}

.m-180 {
	margin: 180px;
}

.m-185 {
	margin: 185px;
}

.m-190 {
	margin: 190px;
}

.m-195 {
	margin: 195px;
}

.m-200 {
	margin: 200px;
}

.m-205 {
	margin: 205px;
}

.m-210 {
	margin: 210px;
}

.m-215 {
	margin: 215px;
}

.m-220 {
	margin: 220px;
}

.m-225 {
	margin: 225px;
}

.m-230 {
	margin: 230px;
}

.m-235 {
	margin: 235px;
}

.m-240 {
	margin: 240px;
}

.m-245 {
	margin: 245px;
}

.m-250 {
	margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
	margin-top: 5px !important;
}

.mt-10,
.my-10 {
	margin-top: 10px;
}

.mt-15,
.my-15 {
	margin-top: 15px;
}

.mt-20,
.my-20 {
	margin-top: 20px;
}

.mt-25,
.my-25 {
	margin-top: 25px;
}

.mt-30,
.my-30 {
	margin-top: 30px;
}

.mt-35,
.my-35 {
	margin-top: 35px;
}

.mt-40,
.my-40 {
	margin-top: 40px;
}

.mt-45,
.my-45 {
	margin-top: 45px;
}

.mt-50,
.my-50 {
	margin-top: 50px;
}

.mt-55,
.my-55 {
	margin-top: 55px;
}

.mt-60,
.my-60 {
	margin-top: 60px;
}

.mt-65,
.my-65 {
	margin-top: 65px;
}

.mt-70,
.my-70 {
	margin-top: 70px;
}

.mt-75,
.my-75 {
	margin-top: 75px;
}

.mt-80,
.my-80 {
	margin-top: 80px;
}

.mt-85,
.my-85 {
	margin-top: 85px;
}

.mt-90,
.my-90 {
	margin-top: 90px;
}

.mt-95,
.my-95 {
	margin-top: 95px;
}

.mt-100,
.my-100 {
	margin-top: 100px;
}

.mt-105,
.my-105 {
	margin-top: 105px;
}

.mt-110,
.my-110 {
	margin-top: 110px;
}

.mt-115,
.my-115 {
	margin-top: 115px;
}

.mt-120,
.my-120 {
	margin-top: 120px;
}

.mt-125,
.my-125 {
	margin-top: 125px;
}

.mt-130,
.my-130 {
	margin-top: 130px;
}

.mt-135,
.my-135 {
	margin-top: 135px;
}

.mt-140,
.my-140 {
	margin-top: 140px;
}

.mt-145,
.my-145 {
	margin-top: 145px;
}

.mt-150,
.my-150 {
	margin-top: 150px;
}

.mt-155,
.my-155 {
	margin-top: 155px;
}

.mt-160,
.my-160 {
	margin-top: 160px;
}

.mt-165,
.my-165 {
	margin-top: 165px;
}

.mt-170,
.my-170 {
	margin-top: 170px;
}

.mt-175,
.my-175 {
	margin-top: 175px;
}

.mt-180,
.my-180 {
	margin-top: 180px;
}

.mt-185,
.my-185 {
	margin-top: 185px;
}

.mt-190,
.my-190 {
	margin-top: 190px;
}

.mt-195,
.my-195 {
	margin-top: 195px;
}

.mt-200,
.my-200 {
	margin-top: 200px;
}

.mt-205,
.my-205 {
	margin-top: 205px;
}

.mt-210,
.my-210 {
	margin-top: 210px;
}

.mt-215,
.my-215 {
	margin-top: 215px;
}

.mt-220,
.my-220 {
	margin-top: 220px;
}

.mt-225,
.my-225 {
	margin-top: 225px;
}

.mt-230,
.my-230 {
	margin-top: 230px;
}

.mt-235,
.my-235 {
	margin-top: 235px;
}

.mt-240,
.my-240 {
	margin-top: 240px;
}

.mt-245,
.my-245 {
	margin-top: 245px;
}

.mt-250,
.my-250 {
	margin-top: 250px;
}

/* Margin Right */
.mr-5,
.mx-5 {
	margin-right: 5px !important;
}

.mr-10,
.mx-10 {
	margin-right: 10px;
}

.mr-15,
.mx-15 {
	margin-right: 15px;
}

.mr-20,
.mx-20 {
	margin-right: 20px;
}

.mr-25,
.mx-25 {
	margin-right: 25px;
}

.mr-30,
.mx-30 {
	margin-right: 30px;
}

.mr-35,
.mx-35 {
	margin-right: 35px;
}

.mr-40,
.mx-40 {
	margin-right: 40px;
}

.mr-45,
.mx-45 {
	margin-right: 45px;
}

.mr-50,
.mx-50 {
	margin-right: 50px;
}

.mr-55,
.mx-55 {
	margin-right: 55px;
}

.mr-60,
.mx-60 {
	margin-right: 60px;
}

.mr-65,
.mx-65 {
	margin-right: 65px;
}

.mr-70,
.mx-70 {
	margin-right: 70px;
}

.mr-75,
.mx-75 {
	margin-right: 75px;
}

.mr-80,
.mx-80 {
	margin-right: 80px;
}

.mr-85,
.mx-85 {
	margin-right: 85px;
}

.mr-90,
.mx-90 {
	margin-right: 90px;
}

.mr-95,
.mx-95 {
	margin-right: 95px;
}

.mr-100,
.mx-100 {
	margin-right: 100px;
}

.mr-105,
.mx-105 {
	margin-right: 105px;
}

.mr-110,
.mx-110 {
	margin-right: 110px;
}

.mr-115,
.mx-115 {
	margin-right: 115px;
}

.mr-120,
.mx-120 {
	margin-right: 120px;
}

.mr-125,
.mx-125 {
	margin-right: 125px;
}

.mr-130,
.mx-130 {
	margin-right: 130px;
}

.mr-135,
.mx-135 {
	margin-right: 135px;
}

.mr-140,
.mx-140 {
	margin-right: 140px;
}

.mr-145,
.mx-145 {
	margin-right: 145px;
}

.mr-150,
.mx-150 {
	margin-right: 150px;
}

.mr-155,
.mx-155 {
	margin-right: 155px;
}

.mr-160,
.mx-160 {
	margin-right: 160px;
}

.mr-165,
.mx-165 {
	margin-right: 165px;
}

.mr-170,
.mx-170 {
	margin-right: 170px;
}

.mr-175,
.mx-175 {
	margin-right: 175px;
}

.mr-180,
.mx-180 {
	margin-right: 180px;
}

.mr-185,
.mx-185 {
	margin-right: 185px;
}

.mr-190,
.mx-190 {
	margin-right: 190px;
}

.mr-195,
.mx-195 {
	margin-right: 195px;
}

.mr-200,
.mx-200 {
	margin-right: 200px;
}

.mr-205,
.mx-205 {
	margin-right: 205px;
}

.mr-210,
.mx-210 {
	margin-right: 210px;
}

.mr-215,
.mx-215 {
	margin-right: 215px;
}

.mr-220,
.mx-220 {
	margin-right: 220px;
}

.mr-225,
.mx-225 {
	margin-right: 225px;
}

.mr-230,
.mx-230 {
	margin-right: 230px;
}

.mr-235,
.mx-235 {
	margin-right: 235px;
}

.mr-240,
.mx-240 {
	margin-right: 240px;
}

.mr-245,
.mx-245 {
	margin-right: 245px;
}

.mr-250,
.mx-250 {
	margin-right: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
	margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
	margin-bottom: 10px;
}

.mb-15,
.my-15 {
	margin-bottom: 15px;
}

.mb-20,
.my-20 {
	margin-bottom: 20px;
}

.mb-25,
.my-25 {
	margin-bottom: 25px;
}

.mb-30,
.my-30 {
	margin-bottom: 30px;
}

.mb-35,
.my-35 {
	margin-bottom: 35px;
}

.mb-40,
.my-40 {
	margin-bottom: 40px;
}

.mb-45,
.my-45 {
	margin-bottom: 45px;
}

.mb-50,
.my-50 {
	margin-bottom: 50px;
}

.mb-55,
.my-55 {
	margin-bottom: 55px;
}

.mb-60,
.my-60 {
	margin-bottom: 60px;
}

.mb-65,
.my-65 {
	margin-bottom: 65px;
}

.mb-70,
.my-70 {
	margin-bottom: 70px;
}

.mb-75,
.my-75 {
	margin-bottom: 75px;
}

.mb-80,
.my-80 {
	margin-bottom: 80px;
}

.mb-85,
.my-85 {
	margin-bottom: 85px;
}

.mb-90,
.my-90 {
	margin-bottom: 90px;
}

.mb-95,
.my-95 {
	margin-bottom: 95px;
}

.mb-100,
.my-100 {
	margin-bottom: 100px;
}

.mb-105,
.my-105 {
	margin-bottom: 105px;
}

.mb-110,
.my-110 {
	margin-bottom: 110px;
}

.mb-115,
.my-115 {
	margin-bottom: 115px;
}

.mb-120,
.my-120 {
	margin-bottom: 120px;
}

.mb-125,
.my-125 {
	margin-bottom: 125px;
}

.mb-130,
.my-130 {
	margin-bottom: 130px;
}

.mb-135,
.my-135 {
	margin-bottom: 135px;
}

.mb-140,
.my-140 {
	margin-bottom: 140px;
}

.mb-145,
.my-145 {
	margin-bottom: 145px;
}

.mb-150,
.my-150 {
	margin-bottom: 150px;
}

.mb-155,
.my-155 {
	margin-bottom: 155px;
}

.mb-160,
.my-160 {
	margin-bottom: 160px;
}

.mb-165,
.my-165 {
	margin-bottom: 165px;
}

.mb-170,
.my-170 {
	margin-bottom: 170px;
}

.mb-175,
.my-175 {
	margin-bottom: 175px;
}

.mb-180,
.my-180 {
	margin-bottom: 180px;
}

.mb-185,
.my-185 {
	margin-bottom: 185px;
}

.mb-190,
.my-190 {
	margin-bottom: 190px;
}

.mb-195,
.my-195 {
	margin-bottom: 195px;
}

.mb-200,
.my-200 {
	margin-bottom: 200px;
}

.mb-205,
.my-205 {
	margin-bottom: 205px;
}

.mb-210,
.my-210 {
	margin-bottom: 210px;
}

.mb-215,
.my-215 {
	margin-bottom: 215px;
}

.mb-220,
.my-220 {
	margin-bottom: 220px;
}

.mb-225,
.my-225 {
	margin-bottom: 225px;
}

.mb-230,
.my-230 {
	margin-bottom: 230px;
}

.mb-235,
.my-235 {
	margin-bottom: 235px;
}

.mb-240,
.my-240 {
	margin-bottom: 240px;
}

.mb-245,
.my-245 {
	margin-bottom: 245px;
}

.mb-250,
.my-250 {
	margin-bottom: 250px;
}

/* Margin Left */
.ml-5,
.mx-5 {
	margin-left: 5px !important;
}

.ml-10,
.mx-10 {
	margin-left: 10px;
}

.ml-15,
.mx-15 {
	margin-left: 15px;
}

.ml-20,
.mx-20 {
	margin-left: 20px;
}

.ml-25,
.mx-25 {
	margin-left: 25px;
}

.ml-30,
.mx-30 {
	margin-left: 30px;
}

.ml-35,
.mx-35 {
	margin-left: 35px;
}

.ml-40,
.mx-40 {
	margin-left: 40px;
}

.ml-45,
.mx-45 {
	margin-left: 45px;
}

.ml-50,
.mx-50 {
	margin-left: 50px;
}

.ml-55,
.mx-55 {
	margin-left: 55px;
}

.ml-60,
.mx-60 {
	margin-left: 60px;
}

.ml-65,
.mx-65 {
	margin-left: 65px;
}

.ml-70,
.mx-70 {
	margin-left: 70px;
}

.ml-75,
.mx-75 {
	margin-left: 75px;
}

.ml-80,
.mx-80 {
	margin-left: 80px;
}

.ml-85,
.mx-85 {
	margin-left: 85px;
}

.ml-90,
.mx-90 {
	margin-left: 90px;
}

.ml-95,
.mx-95 {
	margin-left: 95px;
}

.ml-100,
.mx-100 {
	margin-left: 100px;
}

.ml-105,
.mx-105 {
	margin-left: 105px;
}

.ml-110,
.mx-110 {
	margin-left: 110px;
}

.ml-115,
.mx-115 {
	margin-left: 115px;
}

.ml-120,
.mx-120 {
	margin-left: 120px;
}

.ml-125,
.mx-125 {
	margin-left: 125px;
}

.ml-130,
.mx-130 {
	margin-left: 130px;
}

.ml-135,
.mx-135 {
	margin-left: 135px;
}

.ml-140,
.mx-140 {
	margin-left: 140px;
}

.ml-145,
.mx-145 {
	margin-left: 145px;
}

.ml-150,
.mx-150 {
	margin-left: 150px;
}

.ml-155,
.mx-155 {
	margin-left: 155px;
}

.ml-160,
.mx-160 {
	margin-left: 160px;
}

.ml-165,
.mx-165 {
	margin-left: 165px;
}

.ml-170,
.mx-170 {
	margin-left: 170px;
}

.ml-175,
.mx-175 {
	margin-left: 175px;
}

.ml-180,
.mx-180 {
	margin-left: 180px;
}

.ml-185,
.mx-185 {
	margin-left: 185px;
}

.ml-190,
.mx-190 {
	margin-left: 190px;
}

.ml-195,
.mx-195 {
	margin-left: 195px;
}

.ml-200,
.mx-200 {
	margin-left: 200px;
}

.ml-205,
.mx-205 {
	margin-left: 205px;
}

.ml-210,
.mx-210 {
	margin-left: 210px;
}

.ml-215,
.mx-215 {
	margin-left: 215px;
}

.ml-220,
.mx-220 {
	margin-left: 220px;
}

.ml-225,
.mx-225 {
	margin-left: 225px;
}

.ml-230,
.mx-230 {
	margin-left: 230px;
}

.ml-235,
.mx-235 {
	margin-left: 235px;
}

.ml-240,
.mx-240 {
	margin-left: 240px;
}

.ml-245,
.mx-245 {
	margin-left: 245px;
}

.ml-250,
.mx-250 {
	margin-left: 250px;
}

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {
	/* Padding Around */
	.rp-0 {
		padding: 0px !important;
	}
	.rp-5 {
		padding: 5px !important;
	}
	.rp-10 {
		padding: 10px;
	}
	.rp-15 {
		padding: 15px;
	}
	.rp-20 {
		padding: 20px;
	}
	.rp-25 {
		padding: 25px;
	}
	.rp-30 {
		padding: 30px;
	}
	.rp-35 {
		padding: 35px;
	}
	.rp-40 {
		padding: 40px;
	}
	.rp-45 {
		padding: 45px;
	}
	.rp-50 {
		padding: 50px;
	}
	.rp-55 {
		padding: 55px;
	}
	.rp-60 {
		padding: 60px;
	}
	.rp-65 {
		padding: 65px;
	}
	.rp-70 {
		padding: 70px;
	}
	.rp-75 {
		padding: 75px;
	}
	.rp-80 {
		padding: 80px;
	}
	.rp-85 {
		padding: 85px;
	}
	.rp-90 {
		padding: 90px;
	}
	.rp-95 {
		padding: 95px;
	}
	.rp-100 {
		padding: 100px;
	}
	.rp-105 {
		padding: 105px;
	}
	.rp-110 {
		padding: 110px;
	}
	.rp-115 {
		padding: 115px;
	}
	.rp-120 {
		padding: 120px;
	}
	.rp-125 {
		padding: 125px;
	}
	.rp-130 {
		padding: 130px;
	}
	.rp-135 {
		padding: 135px;
	}
	.rp-140 {
		padding: 140px;
	}
	.rp-145 {
		padding: 145px;
	}
	.rp-150 {
		padding: 150px;
	}
	/* Padding Top */
	.rpt-0,
	.rpy-0 {
		padding-top: 0px !important;
	}
	.rpt-5,
	.rpy-5 {
		padding-top: 5px !important;
	}
	.rpt-10,
	.rpy-10 {
		padding-top: 10px;
	}
	.rpt-15,
	.rpy-15 {
		padding-top: 15px;
	}
	.rpt-20,
	.rpy-20 {
		padding-top: 20px;
	}
	.rpt-25,
	.rpy-25 {
		padding-top: 25px;
	}
	.rpt-30,
	.rpy-30 {
		padding-top: 30px;
	}
	.rpt-35,
	.rpy-35 {
		padding-top: 35px;
	}
	.rpt-40,
	.rpy-40 {
		padding-top: 40px;
	}
	.rpt-45,
	.rpy-45 {
		padding-top: 45px;
	}
	.rpt-50,
	.rpy-50 {
		padding-top: 50px;
	}
	.rpt-55,
	.rpy-55 {
		padding-top: 55px;
	}
	.rpt-60,
	.rpy-60 {
		padding-top: 60px;
	}
	.rpt-65,
	.rpy-65 {
		padding-top: 65px;
	}
	.rpt-70,
	.rpy-70 {
		padding-top: 70px;
	}
	.rpt-75,
	.rpy-75 {
		padding-top: 75px;
	}
	.rpt-80,
	.rpy-80 {
		padding-top: 80px;
	}
	.rpt-85,
	.rpy-85 {
		padding-top: 85px;
	}
	.rpt-90,
	.rpy-90 {
		padding-top: 90px;
	}
	.rpt-95,
	.rpy-95 {
		padding-top: 95px;
	}
	.rpt-100,
	.rpy-100 {
		padding-top: 100px;
	}
	.rpt-105,
	.rpy-105 {
		padding-top: 105px;
	}
	.rpt-110,
	.rpy-110 {
		padding-top: 110px;
	}
	.rpt-115,
	.rpy-115 {
		padding-top: 115px;
	}
	.rpt-120,
	.rpy-120 {
		padding-top: 120px;
	}
	.rpt-125,
	.rpy-125 {
		padding-top: 125px;
	}
	.rpt-130,
	.rpy-130 {
		padding-top: 130px;
	}
	.rpt-135,
	.rpy-135 {
		padding-top: 135px;
	}
	.rpt-140,
	.rpy-140 {
		padding-top: 140px;
	}
	.rpt-145,
	.rpy-145 {
		padding-top: 145px;
	}
	.rpt-150,
	.rpy-150 {
		padding-top: 150px;
	}
	/* Padding Right */
	.rpr-0,
	.rpx-0 {
		padding-right: 0px !important;
	}
	.rpr-5,
	.rpx-5 {
		padding-right: 5px !important;
	}
	.rpr-10,
	.rpx-10 {
		padding-right: 10px;
	}
	.rpr-15,
	.rpx-15 {
		padding-right: 15px;
	}
	.rpr-20,
	.rpx-20 {
		padding-right: 20px;
	}
	.rpr-25,
	.rpx-25 {
		padding-right: 25px;
	}
	.rpr-30,
	.rpx-30 {
		padding-right: 30px;
	}
	.rpr-35,
	.rpx-35 {
		padding-right: 35px;
	}
	.rpr-40,
	.rpx-40 {
		padding-right: 40px;
	}
	.rpr-45,
	.rpx-45 {
		padding-right: 45px;
	}
	.rpr-50,
	.rpx-50 {
		padding-right: 50px;
	}
	.rpr-55,
	.rpx-55 {
		padding-right: 55px;
	}
	.rpr-60,
	.rpx-60 {
		padding-right: 60px;
	}
	.rpr-65,
	.rpx-65 {
		padding-right: 65px;
	}
	.rpr-70,
	.rpx-70 {
		padding-right: 70px;
	}
	.rpr-75,
	.rpx-75 {
		padding-right: 75px;
	}
	.rpr-80,
	.rpx-80 {
		padding-right: 80px;
	}
	.rpr-85,
	.rpx-85 {
		padding-right: 85px;
	}
	.rpr-90,
	.rpx-90 {
		padding-right: 90px;
	}
	.rpr-95,
	.rpx-95 {
		padding-right: 95px;
	}
	.rpr-100,
	.rpx-100 {
		padding-right: 100px;
	}
	.rpr-105,
	.rpx-105 {
		padding-right: 105px;
	}
	.rpr-110,
	.rpx-110 {
		padding-right: 110px;
	}
	.rpr-115,
	.rpx-115 {
		padding-right: 115px;
	}
	.rpr-120,
	.rpx-120 {
		padding-right: 120px;
	}
	.rpr-125,
	.rpx-125 {
		padding-right: 125px;
	}
	.rpr-130,
	.rpx-130 {
		padding-right: 130px;
	}
	.rpr-135,
	.rpx-135 {
		padding-right: 135px;
	}
	.rpr-140,
	.rpx-140 {
		padding-right: 140px;
	}
	.rpr-145,
	.rpx-145 {
		padding-right: 145px;
	}
	.rpr-150,
	.rpx-150 {
		padding-right: 150px;
	}
	/* Padding Bottom */
	.rpb-0,
	.rpy-0 {
		padding-bottom: 0px !important;
	}
	.rpb-5,
	.rpy-5 {
		padding-bottom: 5px !important;
	}
	.rpb-10,
	.rpy-10 {
		padding-bottom: 10px;
	}
	.rpb-15,
	.rpy-15 {
		padding-bottom: 15px;
	}
	.rpb-20,
	.rpy-20 {
		padding-bottom: 20px;
	}
	.rpb-25,
	.rpy-25 {
		padding-bottom: 25px;
	}
	.rpb-30,
	.rpy-30 {
		padding-bottom: 30px;
	}
	.rpb-35,
	.rpy-35 {
		padding-bottom: 35px;
	}
	.rpb-40,
	.rpy-40 {
		padding-bottom: 40px;
	}
	.rpb-45,
	.rpy-45 {
		padding-bottom: 45px;
	}
	.rpb-50,
	.rpy-50 {
		padding-bottom: 50px;
	}
	.rpb-55,
	.rpy-55 {
		padding-bottom: 55px;
	}
	.rpb-60,
	.rpy-60 {
		padding-bottom: 60px;
	}
	.rpb-65,
	.rpy-65 {
		padding-bottom: 65px;
	}
	.rpb-70,
	.rpy-70 {
		padding-bottom: 70px;
	}
	.rpb-75,
	.rpy-75 {
		padding-bottom: 75px;
	}
	.rpb-80,
	.rpy-80 {
		padding-bottom: 80px;
	}
	.rpb-85,
	.rpy-85 {
		padding-bottom: 85px;
	}
	.rpb-90,
	.rpy-90 {
		padding-bottom: 90px;
	}
	.rpb-95,
	.rpy-95 {
		padding-bottom: 95px;
	}
	.rpb-100,
	.rpy-100 {
		padding-bottom: 100px;
	}
	.rpb-105,
	.rpy-105 {
		padding-bottom: 105px;
	}
	.rpb-110,
	.rpy-110 {
		padding-bottom: 110px;
	}
	.rpb-115,
	.rpy-115 {
		padding-bottom: 115px;
	}
	.rpb-120,
	.rpy-120 {
		padding-bottom: 120px;
	}
	.rpb-125,
	.rpy-125 {
		padding-bottom: 125px;
	}
	.rpb-130,
	.rpy-130 {
		padding-bottom: 130px;
	}
	.rpb-135,
	.rpy-135 {
		padding-bottom: 135px;
	}
	.rpb-140,
	.rpy-140 {
		padding-bottom: 140px;
	}
	.rpb-145,
	.rpy-145 {
		padding-bottom: 145px;
	}
	.rpb-150,
	.rpy-150 {
		padding-bottom: 150px;
	}
	/* Padding Left */
	.rpl-0,
	.rpx-0 {
		padding-left: 0px !important;
	}
	.rpl-5,
	.rpx-5 {
		padding-left: 5px !important;
	}
	.rpl-10,
	.rpx-10 {
		padding-left: 10px;
	}
	.rpl-15,
	.rpx-15 {
		padding-left: 15px;
	}
	.rpl-20,
	.rpx-20 {
		padding-left: 20px;
	}
	.rpl-25,
	.rpx-25 {
		padding-left: 25px;
	}
	.rpl-30,
	.rpx-30 {
		padding-left: 30px;
	}
	.rpl-35,
	.rpx-35 {
		padding-left: 35px;
	}
	.rpl-40,
	.rpx-40 {
		padding-left: 40px;
	}
	.rpl-45,
	.rpx-45 {
		padding-left: 45px;
	}
	.rpl-50,
	.rpx-50 {
		padding-left: 50px;
	}
	.rpl-55,
	.rpx-55 {
		padding-left: 55px;
	}
	.rpl-60,
	.rpx-60 {
		padding-left: 60px;
	}
	.rpl-65,
	.rpx-65 {
		padding-left: 65px;
	}
	.rpl-70,
	.rpx-70 {
		padding-left: 70px;
	}
	.rpl-75,
	.rpx-75 {
		padding-left: 75px;
	}
	.rpl-80,
	.rpx-80 {
		padding-left: 80px;
	}
	.rpl-85,
	.rpx-85 {
		padding-left: 85px;
	}
	.rpl-90,
	.rpx-90 {
		padding-left: 90px;
	}
	.rpl-95,
	.rpx-95 {
		padding-left: 95px;
	}
	.rpl-100,
	.rpx-100 {
		padding-left: 100px;
	}
	.rpl-105,
	.rpx-105 {
		padding-left: 105px;
	}
	.rpl-110,
	.rpx-110 {
		padding-left: 110px;
	}
	.rpl-115,
	.rpx-115 {
		padding-left: 115px;
	}
	.rpl-120,
	.rpx-120 {
		padding-left: 120px;
	}
	.rpl-125,
	.rpx-125 {
		padding-left: 125px;
	}
	.rpl-130,
	.rpx-130 {
		padding-left: 130px;
	}
	.rpl-135,
	.rpx-135 {
		padding-left: 135px;
	}
	.rpl-140,
	.rpx-140 {
		padding-left: 140px;
	}
	.rpl-145,
	.rpx-145 {
		padding-left: 145px;
	}
	.rpl-150,
	.rpx-150 {
		padding-left: 150px;
	}
	/* Margin Around */
	.rm-0 {
		margin: 0px !important;
	}
	.rm-5 {
		margin: 5px !important;
	}
	.rm-10 {
		margin: 10px;
	}
	.rm-15 {
		margin: 15px;
	}
	.rm-20 {
		margin: 20px;
	}
	.rm-25 {
		margin: 25px;
	}
	.rm-30 {
		margin: 30px;
	}
	.rm-35 {
		margin: 35px;
	}
	.rm-40 {
		margin: 40px;
	}
	.rm-45 {
		margin: 45px;
	}
	.rm-50 {
		margin: 50px;
	}
	.rm-55 {
		margin: 55px;
	}
	.rm-60 {
		margin: 60px;
	}
	.rm-65 {
		margin: 65px;
	}
	.rm-70 {
		margin: 70px;
	}
	.rm-75 {
		margin: 75px;
	}
	.rm-80 {
		margin: 80px;
	}
	.rm-85 {
		margin: 85px;
	}
	.rm-90 {
		margin: 90px;
	}
	.rm-95 {
		margin: 95px;
	}
	.rm-100 {
		margin: 100px;
	}
	.rm-105 {
		margin: 105px;
	}
	.rm-110 {
		margin: 110px;
	}
	.rm-115 {
		margin: 115px;
	}
	.rm-120 {
		margin: 120px;
	}
	.rm-125 {
		margin: 125px;
	}
	.rm-130 {
		margin: 130px;
	}
	.rm-135 {
		margin: 135px;
	}
	.rm-140 {
		margin: 140px;
	}
	.rm-145 {
		margin: 145px;
	}
	.rm-150 {
		margin: 150px;
	}
	/* Margin Top */
	.rmt-0,
	.rmy-0 {
		margin-top: 0px !important;
	}
	.rmt-5,
	.rmy-5 {
		margin-top: 5px !important;
	}
	.rmt-10,
	.rmy-10 {
		margin-top: 10px;
	}
	.rmt-15,
	.rmy-15 {
		margin-top: 15px;
	}
	.rmt-20,
	.rmy-20 {
		margin-top: 20px;
	}
	.rmt-25,
	.rmy-25 {
		margin-top: 25px;
	}
	.rmt-30,
	.rmy-30 {
		margin-top: 30px;
	}
	.rmt-35,
	.rmy-35 {
		margin-top: 35px;
	}
	.rmt-40,
	.rmy-40 {
		margin-top: 40px;
	}
	.rmt-45,
	.rmy-45 {
		margin-top: 45px;
	}
	.rmt-50,
	.rmy-50 {
		margin-top: 50px;
	}
	.rmt-55,
	.rmy-55 {
		margin-top: 55px;
	}
	.rmt-60,
	.rmy-60 {
		margin-top: 60px;
	}
	.rmt-65,
	.rmy-65 {
		margin-top: 65px;
	}
	.rmt-70,
	.rmy-70 {
		margin-top: 70px;
	}
	.rmt-75,
	.rmy-75 {
		margin-top: 75px;
	}
	.rmt-80,
	.rmy-80 {
		margin-top: 80px;
	}
	.rmt-85,
	.rmy-85 {
		margin-top: 85px;
	}
	.rmt-90,
	.rmy-90 {
		margin-top: 90px;
	}
	.rmt-95,
	.rmy-95 {
		margin-top: 95px;
	}
	.rmt-100,
	.rmy-100 {
		margin-top: 100px;
	}
	.rmt-105,
	.rmy-105 {
		margin-top: 105px;
	}
	.rmt-110,
	.rmy-110 {
		margin-top: 110px;
	}
	.rmt-115,
	.rmy-115 {
		margin-top: 115px;
	}
	.rmt-120,
	.rmy-120 {
		margin-top: 120px;
	}
	.rmt-125,
	.rmy-125 {
		margin-top: 125px;
	}
	.rmt-130,
	.rmy-130 {
		margin-top: 130px;
	}
	.rmt-135,
	.rmy-135 {
		margin-top: 135px;
	}
	.rmt-140,
	.rmy-140 {
		margin-top: 140px;
	}
	.rmt-145,
	.rmy-145 {
		margin-top: 145px;
	}
	.rmt-150,
	.rmy-150 {
		margin-top: 150px;
	}
	/* Margin Right */
	.rmr-0,
	.rmx-0 {
		margin-right: 0px !important;
	}
	.rmr-5,
	.rmx-5 {
		margin-right: 5px !important;
	}
	.rmr-10,
	.rmx-10 {
		margin-right: 10px;
	}
	.rmr-15,
	.rmx-15 {
		margin-right: 15px;
	}
	.rmr-20,
	.rmx-20 {
		margin-right: 20px;
	}
	.rmr-25,
	.rmx-25 {
		margin-right: 25px;
	}
	.rmr-30,
	.rmx-30 {
		margin-right: 30px;
	}
	.rmr-35,
	.rmx-35 {
		margin-right: 35px;
	}
	.rmr-40,
	.rmx-40 {
		margin-right: 40px;
	}
	.rmr-45,
	.rmx-45 {
		margin-right: 45px;
	}
	.rmr-50,
	.rmx-50 {
		margin-right: 50px;
	}
	.rmr-55,
	.rmx-55 {
		margin-right: 55px;
	}
	.rmr-60,
	.rmx-60 {
		margin-right: 60px;
	}
	.rmr-65,
	.rmx-65 {
		margin-right: 65px;
	}
	.rmr-70,
	.rmx-70 {
		margin-right: 70px;
	}
	.rmr-75,
	.rmx-75 {
		margin-right: 75px;
	}
	.rmr-80,
	.rmx-80 {
		margin-right: 80px;
	}
	.rmr-85,
	.rmx-85 {
		margin-right: 85px;
	}
	.rmr-90,
	.rmx-90 {
		margin-right: 90px;
	}
	.rmr-95,
	.rmx-95 {
		margin-right: 95px;
	}
	.rmr-100,
	.rmx-100 {
		margin-right: 100px;
	}
	.rmr-105,
	.rmx-105 {
		margin-right: 105px;
	}
	.rmr-110,
	.rmx-110 {
		margin-right: 110px;
	}
	.rmr-115,
	.rmx-115 {
		margin-right: 115px;
	}
	.rmr-120,
	.rmx-120 {
		margin-right: 120px;
	}
	.rmr-125,
	.rmx-125 {
		margin-right: 125px;
	}
	.rmr-130,
	.rmx-130 {
		margin-right: 130px;
	}
	.rmr-135,
	.rmx-135 {
		margin-right: 135px;
	}
	.rmr-140,
	.rmx-140 {
		margin-right: 140px;
	}
	.rmr-145,
	.rmx-145 {
		margin-right: 145px;
	}
	.rmr-150,
	.rmx-150 {
		margin-right: 150px;
	}
	/* Margin Bottom */
	.rmb-0,
	.rmy-0 {
		margin-bottom: 0px !important;
	}
	.rmb-5,
	.rmy-5 {
		margin-bottom: 5px !important;
	}
	.rmb-10,
	.rmy-10 {
		margin-bottom: 10px;
	}
	.rmb-15,
	.rmy-15 {
		margin-bottom: 15px;
	}
	.rmb-20,
	.rmy-20 {
		margin-bottom: 20px;
	}
	.rmb-25,
	.rmy-25 {
		margin-bottom: 25px;
	}
	.rmb-30,
	.rmy-30 {
		margin-bottom: 30px;
	}
	.rmb-35,
	.rmy-35 {
		margin-bottom: 35px;
	}
	.rmb-40,
	.rmy-40 {
		margin-bottom: 40px;
	}
	.rmb-45,
	.rmy-45 {
		margin-bottom: 45px;
	}
	.rmb-50,
	.rmy-50 {
		margin-bottom: 50px;
	}
	.rmb-55,
	.rmy-55 {
		margin-bottom: 55px;
	}
	.rmb-60,
	.rmy-60 {
		margin-bottom: 60px;
	}
	.rmb-65,
	.rmy-65 {
		margin-bottom: 65px;
	}
	.rmb-70,
	.rmy-70 {
		margin-bottom: 70px;
	}
	.rmb-75,
	.rmy-75 {
		margin-bottom: 75px;
	}
	.rmb-80,
	.rmy-80 {
		margin-bottom: 80px;
	}
	.rmb-85,
	.rmy-85 {
		margin-bottom: 85px;
	}
	.rmb-90,
	.rmy-90 {
		margin-bottom: 90px;
	}
	.rmb-95,
	.rmy-95 {
		margin-bottom: 95px;
	}
	.rmb-100,
	.rmy-100 {
		margin-bottom: 100px;
	}
	.rmb-105,
	.rmy-105 {
		margin-bottom: 105px;
	}
	.rmb-110,
	.rmy-110 {
		margin-bottom: 110px;
	}
	.rmb-115,
	.rmy-115 {
		margin-bottom: 115px;
	}
	.rmb-120,
	.rmy-120 {
		margin-bottom: 120px;
	}
	.rmb-125,
	.rmy-125 {
		margin-bottom: 125px;
	}
	.rmb-130,
	.rmy-130 {
		margin-bottom: 130px;
	}
	.rmb-135,
	.rmy-135 {
		margin-bottom: 135px;
	}
	.rmb-140,
	.rmy-140 {
		margin-bottom: 140px;
	}
	.rmb-145,
	.rmy-145 {
		margin-bottom: 145px;
	}
	.rmb-150,
	.rmy-150 {
		margin-bottom: 150px;
	}
	/* Margin Left */
	.rml-0,
	.rmx-0 {
		margin-left: 0px !important;
	}
	.rml-5,
	.rmx-5 {
		margin-left: 5px !important;
	}
	.rml-10,
	.rmx-10 {
		margin-left: 10px;
	}
	.rml-15,
	.rmx-15 {
		margin-left: 15px;
	}
	.rml-20,
	.rmx-20 {
		margin-left: 20px;
	}
	.rml-25,
	.rmx-25 {
		margin-left: 25px;
	}
	.rml-30,
	.rmx-30 {
		margin-left: 30px;
	}
	.rml-35,
	.rmx-35 {
		margin-left: 35px;
	}
	.rml-40,
	.rmx-40 {
		margin-left: 40px;
	}
	.rml-45,
	.rmx-45 {
		margin-left: 45px;
	}
	.rml-50,
	.rmx-50 {
		margin-left: 50px;
	}
	.rml-55,
	.rmx-55 {
		margin-left: 55px;
	}
	.rml-60,
	.rmx-60 {
		margin-left: 60px;
	}
	.rml-65,
	.rmx-65 {
		margin-left: 65px;
	}
	.rml-70,
	.rmx-70 {
		margin-left: 70px;
	}
	.rml-75,
	.rmx-75 {
		margin-left: 75px;
	}
	.rml-80,
	.rmx-80 {
		margin-left: 80px;
	}
	.rml-85,
	.rmx-85 {
		margin-left: 85px;
	}
	.rml-90,
	.rmx-90 {
		margin-left: 90px;
	}
	.rml-95,
	.rmx-95 {
		margin-left: 95px;
	}
	.rml-100,
	.rmx-100 {
		margin-left: 100px;
	}
	.rml-105,
	.rmx-105 {
		margin-left: 105px;
	}
	.rml-110,
	.rmx-110 {
		margin-left: 110px;
	}
	.rml-115,
	.rmx-115 {
		margin-left: 115px;
	}
	.rml-120,
	.rmx-120 {
		margin-left: 120px;
	}
	.rml-125,
	.rmx-125 {
		margin-left: 125px;
	}
	.rml-130,
	.rmx-130 {
		margin-left: 130px;
	}
	.rml-135,
	.rmx-135 {
		margin-left: 135px;
	}
	.rml-140,
	.rmx-140 {
		margin-left: 140px;
	}
	.rml-145,
	.rmx-145 {
		margin-left: 145px;
	}
	.rml-150,
	.rmx-150 {
		margin-left: 150px;
	}
}

/*******************************************************/
/***************** 05. Custom Animation ****************/
/*******************************************************/
/** Header Section Animation **/
.main-header .logo,
.main-menu .navigation > li {
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.main-header .logo {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.main-menu .navigation > li:nth-child(1) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.main-menu .navigation > li:nth-child(2) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.main-menu .navigation > li:nth-child(3) {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.main-menu .navigation > li:nth-child(4) {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.main-menu .navigation > li:nth-child(5) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.main-menu .navigation > li:nth-child(6) {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.main-menu .navigation > li:nth-child(7) {
	-webkit-animation-delay: 1.4s;
	animation-delay: 1.4s;
}

.main-menu .navigation > li:nth-child(8) {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
}

.main-menu .navigation > li:nth-child(9) {
	-webkit-animation-delay: 1.8s;
	animation-delay: 1.8s;
}

.main-menu .navigation > li:nth-child(10) {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

@media only screen and (max-width: 1199px) {
	.main-menu .navigation > li {
		-webkit-animation: none;
		animation: none;
	}
}

/* Animation Delay */
.delay-1-0s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.delay-2-0s {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.delay-0-1s {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.delay-0-2s {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.delay-0-3s {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.delay-0-4s {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.delay-0-5s {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.delay-0-6s {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.delay-0-7s {
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

.delay-0-8s {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.delay-0-9s {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}

.delay-1-1s {
	-webkit-animation-delay: 1.1s;
	animation-delay: 1.1s;
}

.delay-1-2s {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.delay-1-3s {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.delay-1-4s {
	-webkit-animation-delay: 1.4s;
	animation-delay: 1.4s;
}

.delay-1-5s {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.delay-1-6s {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
}

.delay-1-7s {
	-webkit-animation-delay: 1.7s;
	animation-delay: 1.7s;
}

.delay-1-8s {
	-webkit-animation-delay: 1.8s;
	animation-delay: 1.8s;
}

.delay-1-9s {
	-webkit-animation-delay: 1.9s;
	animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
	0% {
		top: -100px;
	}
	100% {
		top: 0;
	}
}

@keyframes sticky {
	0% {
		top: -100px;
	}
	100% {
		top: 0;
	}
}

/* Hero Circle */
@-webkit-keyframes hero_circle {
	0%,
	100% {
		-webkit-transform: translate(-40%, 40%) rotate(0deg);
		transform: translate(-40%, 40%) rotate(0deg);
	}
	50% {
		-webkit-transform: translate(-40%, 40%) rotate(-111deg);
		transform: translate(-40%, 40%) rotate(-111deg);
	}
}
@keyframes hero_circle {
	0%,
	100% {
		-webkit-transform: translate(-40%, 40%) rotate(0deg);
		transform: translate(-40%, 40%) rotate(0deg);
	}
	50% {
		-webkit-transform: translate(-40%, 40%) rotate(-111deg);
		transform: translate(-40%, 40%) rotate(-111deg);
	}
}

/* Preloader */
@-webkit-keyframes animate {
	0%,
	100% {
		-webkit-transform: rotateX(8.75deg) rotateY(35deg);
		transform: rotateX(8.75deg) rotateY(35deg);
	}
	50% {
		-webkit-transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
		transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
	}
}
@keyframes animate {
	0%,
	100% {
		-webkit-transform: rotateX(8.75deg) rotateY(35deg);
		transform: rotateX(8.75deg) rotateY(35deg);
	}
	50% {
		-webkit-transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
		transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
	}
}

@-webkit-keyframes animate_1 {
	0%,
	100% {
		-webkit-transform: translateZ(25px) rotateX(14deg);
		transform: translateZ(25px) rotateX(14deg);
	}
	33% {
		-webkit-transform: translateZ(-25px) scale(0.4);
		transform: translateZ(-25px) scale(0.4);
	}
	66% {
		-webkit-transform: translateZ(-25px);
		transform: translateZ(-25px);
	}
}

@keyframes animate_1 {
	0%,
	100% {
		-webkit-transform: translateZ(25px) rotateX(14deg);
		transform: translateZ(25px) rotateX(14deg);
	}
	33% {
		-webkit-transform: translateZ(-25px) scale(0.4);
		transform: translateZ(-25px) scale(0.4);
	}
	66% {
		-webkit-transform: translateZ(-25px);
		transform: translateZ(-25px);
	}
}

/* About Image Animation */
@-webkit-keyframes borderRadius1 {
	0% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}
	50% {
		border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
	}
	100% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}
}
@keyframes borderRadius1 {
	0% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}
	50% {
		border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
	}
	100% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}
}

@-webkit-keyframes borderRadius2 {
	0% {
		border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
	}
	50% {
		border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
	}
	100% {
		border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
	}
}

@keyframes borderRadius2 {
	0% {
		border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
	}
	50% {
		border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
	}
	100% {
		border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
	}
}

@-webkit-keyframes shapeAnimationOne {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(0px, 150px) rotate(90deg);
		transform: translate(0px, 150px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(150px, 0px) rotate(270deg);
		transform: translate(150px, 0px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationOne {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(0px, 150px) rotate(90deg);
		transform: translate(0px, 150px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(150px, 0px) rotate(270deg);
		transform: translate(150px, 0px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationTwo {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-150px, 0px) rotate(270deg);
		transform: translate(-150px, 0px) rotate(270deg);
	}
	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(0px, -150px) rotate(90deg);
		transform: translate(0px, -150px) rotate(90deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationTwo {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-150px, 0px) rotate(270deg);
		transform: translate(-150px, 0px) rotate(270deg);
	}
	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(0px, -150px) rotate(90deg);
		transform: translate(0px, -150px) rotate(90deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationThree {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(50px, 150px) rotate(90deg);
		transform: translate(50px, 150px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(150px, 50px) rotate(270deg);
		transform: translate(150px, 50px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationThree {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(50px, 150px) rotate(90deg);
		transform: translate(50px, 150px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(150px, 50px) rotate(270deg);
		transform: translate(150px, 50px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationFour {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-150px -50px) rotate(90deg);
		transform: translate(-150px -50px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(-50px, -150px) rotate(270deg);
		transform: translate(-50px, -150px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationFour {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-150px -50px) rotate(90deg);
		transform: translate(-150px -50px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(-50px, -150px) rotate(270deg);
		transform: translate(-50px, -150px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationFive {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-100px -100px) rotate(90deg);
		transform: translate(-100px -100px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(100px, 50px) rotate(180deg);
		transform: translate(100px, 50px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(-100px, 150px) rotate(270deg);
		transform: translate(-100px, 150px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationFive {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	25% {
		-webkit-transform: translate(-100px -100px) rotate(90deg);
		transform: translate(-100px -100px) rotate(90deg);
	}
	50% {
		-webkit-transform: translate(100px, 50px) rotate(180deg);
		transform: translate(100px, 50px) rotate(180deg);
	}
	75% {
		-webkit-transform: translate(-100px, 150px) rotate(270deg);
		transform: translate(-100px, 150px) rotate(270deg);
	}
	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes down-up-one {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
	50% {
		-webkit-transform: rotateX(0deg) translateY(25px);
		transform: rotateX(0deg) translateY(25px);
	}
	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
}

@keyframes down-up-one {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
	50% {
		-webkit-transform: rotateX(0deg) translateY(25px);
		transform: rotateX(0deg) translateY(25px);
	}
	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
}

@-webkit-keyframes down-up-two {
	0% {
		-webkit-transform: rotateX(0deg) translate(0px);
		transform: rotateX(0deg) translate(0px);
	}
	50% {
		-webkit-transform: rotateX(0deg) translate(25px, -25px);
		transform: rotateX(0deg) translate(25px, -25px);
	}
	100% {
		-webkit-transform: rotateX(0deg) translate(0px);
		transform: rotateX(0deg) translate(0px);
	}
}

@keyframes down-up-two {
	0% {
		-webkit-transform: rotateX(0deg) translate(0px);
		transform: rotateX(0deg) translate(0px);
	}
	50% {
		-webkit-transform: rotateX(0deg) translate(25px, -25px);
		transform: rotateX(0deg) translate(25px, -25px);
	}
	100% {
		-webkit-transform: rotateX(0deg) translate(0px);
		transform: rotateX(0deg) translate(0px);
	}
}

@-webkit-keyframes moveLeftRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes moveLeftRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@-webkit-keyframes zoomInOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

@keyframes zoomInOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

/*******************************************************/
/******************* 06. Header style ******************/
/*******************************************************/
.main-header {
	position: relative;
	left: 0px;
	top: 0px;
	z-index: 999;
	width: 100%;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.main-header .header-upper {
	z-index: 5;
	width: 100%;
	position: relative;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
	.main-header .logo-outer {
		display: none;
	}
}
.main-header .logo {
	z-index: 9;
	padding: 2px 0;
	position: relative;
}
.main-header.fixed-header .header-upper {
	top: 0;
	left: 0;
	position: fixed;
	-webkit-animation: sticky 1s;
	animation: sticky 1s;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
	box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

.nav-outer {
	width: 100%;
	position: relative;
}
@media only screen and (max-width: 991px) {
	.nav-outer {
		position: static;
	}
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
	.main-menu {
		width: 100%;
	}
}

/* Header Four */
.header-style .container {
	max-width: 1450px;
}

.header-style .header-upper {
	position: absolute;
}

.header-style.fixed-header .header-upper {
	background: #f8f8f8;
}

/*******************************************************/
/******************* 07. Hero Section ******************/
/*******************************************************/
.hero-section {
	z-index: 1;
	overflow: hidden;
	position: relative;
}

.hero-content .sub-title {
	font-size: 18px;
	font-weight: 500;
}

@media only screen and (max-width: 1199px) {
	.hero-content h1 {
		font-size: 60px;
	}
}

@media only screen and (max-width: 991px) {
	.hero-content h1 {
		font-size: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.hero-content h1 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.hero-content h1 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 375px) {
	.hero-content h1 {
		font-size: 30px;
	}
}

.hero-section:before {
	opacity: 1;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#c18a1588),
		to(transparent)
	);
	background: -webkit-linear-gradient(right, #c18a1588, transparent);
	background: -o-linear-gradient(right, #c18a1588, transparent);
	background: linear-gradient(to left, #c18a1588, transparent);
}

.hero-line-shape {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 30%;
	z-index: -1;
}

.hero-btns .theme-btn {
	margin-right: 12px;
	color: #231f20;
}

/*******************************************************/
/******************* 08. Page Banner *******************/
/*******************************************************/
.page-banner {
	min-height: 550px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
@media only screen and (max-width: 991px) {
	.page-banner {
		min-height: 450px;
	}
}
@media only screen and (max-width: 767px) {
	.page-banner {
		min-height: 400px;
	}
}
.page-banner:before {
	background: #c59d4d;
}
.page-banner .page-title {
	color: #f8f8f8;
	text-transform: capitalize;
}
@media only screen and (max-width: 991px) {
	.page-banner .page-title {
		font-size: 55px;
	}
}
@media only screen and (max-width: 767px) {
	.page-banner .page-title {
		font-size: 45px;
	}
}
@media only screen and (max-width: 375px) {
	.page-banner .page-title {
		font-size: 38px;
	}
}
.page-banner .breadcrumb {
	margin: 0;
	padding: 0;
	background: transparent;
	text-transform: capitalize;
}
.page-banner .breadcrumb .breadcrumb-item {
	color: #f8f8f8;
	font-size: 18px;
	font-weight: 500;
}
.page-banner .breadcrumb .breadcrumb-item a {
	color: #f8f8f8;
}
.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
	content: '-';
	color: #f8f8f8;
}

/*******************************************************/
/****************** 09. About Section ******************/
/*******************************************************/
.about-section {
	z-index: 1;
	position: relative;
}
.about-section:after {
	content: '';
	height: 90%;
	width: 40%;
	right: 0;
	bottom: 0;
	z-index: -1;
	position: absolute;
	background: url(../images/about/about-bg-shape.png) no-repeat bottom;
}

.about-content > i {
	color: black;
	display: block;
	margin-right: 25px;
	margin-bottom: 10px;
}

.about-image {
	max-width: 500px;
	position: relative;
}

.about-image:before {
	content: '';
	z-index: -1;
	left: -45px;
	bottom: -35px;
	position: absolute;
	width: 130px;
	height: 100px;
	background: url(../images/about/about-bg-dots.png) no-repeat;
}

.about-image img {
	border-radius: 220px 220px 0 0;
}

.about-image:before {
	bottom: 15%;
}

.about-image img {
	width: 67%;
	border-radius: 5px;
}
.about-image img:last-child {
	margin: -33% 0 0 33%;
}

.about-images .about-bg-circle {
	z-index: -1;
	margin-top: -25px;
	position: relative;
}

.about-images .bg-circle-dtos,
.about-images .about-bg-circle {
	max-width: 60%;
}

.about-tab-section .fact-counter-inner {
	-webkit-transform: translateY(-120px);
	-ms-transform: translateY(-120px);
	transform: translateY(-120px);
}
@media only screen and (max-width: 991px) {
	.about-tab-section .fact-counter-inner {
		-webkit-transform: translateY(-95px);
		-ms-transform: translateY(-95px);
		transform: translateY(-95px);
	}
}

.about-tab {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 2px solid #3e3216;
}
@media only screen and (max-width: 767px) {
	.about-tab {
		border-bottom: none;
	}
}
.about-tab li:last-child a {
	padding-right: 0;
}
.about-tab li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	position: relative;
	padding: 0 20px 25px 0;
}
@media only screen and (max-width: 767px) {
	.about-tab li a {
		margin-bottom: 15px;
		padding-bottom: 10px;
	}
}
.about-tab li a.active i {
	color: #f7d17f;
}
.about-tab li a.active:before {
	width: 100%;
}
.about-tab li a:before {
	content: '';
	height: 3px;
	width: 0;
	left: 0;
	top: 100%;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	background: #f7d17f;
}
.about-tab li a i {
	font-size: 40px;
	margin-right: 20px;
}
@media only screen and (max-width: 991px) {
	.about-tab li a i {
		font-size: 30px;
		margin-right: 10px;
	}
}
.about-tab li a h3 {
	margin: 0;
}
@media only screen and (max-width: 991px) {
	.about-tab li a h3 {
		font-size: 16px;
	}
}

.about-tab-content {
	padding-top: 65px;
}

.about-shape {
	z-index: 1;
	max-width: 520px;
	position: relative;
}
@media only screen and (max-width: 767px) {
	.about-shape {
		margin-top: 55px;
	}
}
.about-shape .about-graph {
	position: absolute;
	bottom: -5%;
	left: -8%;
	width: 50%;
	-webkit-animation: bounce 15s infinite linear;
	animation: bounce 15s infinite linear;
}
.about-shape:before {
	content: '';
	opacity: 0.1;
	z-index: -1;
	top: 0;
	right: 0;
	position: absolute;
	width: 95%;
	height: 95%;
	background: #ffb81a;
	line-height: 95%;
	border-radius: 50%;
	text-align: center;
	-webkit-animation: down-up-one 5s infinite linear;
	animation: down-up-one 5s infinite linear;
}

/*******************************************************/
/***************** 10. Services Section ****************/
/*******************************************************/
.service-box {
	margin-bottom: 30px;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.service-box:hover .service-normal {
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
}
.service-box:hover .service-hover {
	bottom: 0;
}

.service-normal,
.service-hover {
	padding: 25px;
	border-radius: 5px;
}

.service-normal {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border: 1px solid #e7edf8;
}
.service-normal h6 {
	margin-bottom: 15px;
}
.service-normal .btn-circle {
	margin-top: 10px;
	color: #ffb81a;
	display: inline-block;
	width: 33px;
	height: 33px;
	background: #e5eef6;
	line-height: 33px;
	border-radius: 50%;
	text-align: center;
}

.service-hover {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 100%;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.service-hover h3 {
	margin-bottom: 20px;
}
.service-hover .theme-btn {
	display: block;
	margin-top: 30px;
	padding: 10px 20px;
	background: #ffb81a;
}

.service-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 400px;
	margin-bottom: 30px;
}
.service-item .icon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: #ffb81a;
	font-size: 25px;
	padding-top: 3px;
	margin-right: 20px;
	width: 60px;
	height: 60px;
	background: #ffb81a1f;
	line-height: 60px;
	border-radius: 50%;
	text-align: center;
}
.service-item .service-content h5 {
	margin-bottom: 15px;
}
.service-item:last-child {
	margin-bottom: 0;
}
.service-item:last-child .icon {
	color: #f7d17f;
	background: #ffdb591f;
}

/* Service Details */
.service-details-content h2,
.service-details-content h3,
.service-details-content h4,
.service-details-content h5,
.service-details-content h6,
.service-details-content p {
	margin-bottom: 15px;
}

.service-details-content h2 {
	font-size: 30px;
	font-weight: 500;
}

.service-details-content h3 {
	font-size: 24px;
	font-weight: 500;
}

.service-details-content .list-style-c {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.service-details-content .list-style-c li {
	width: 45%;
	margin-bottom: 15px;
}
@media only screen and (max-width: 480px) {
	.service-details-content .list-style-c li {
		width: 100%;
	}
}

.service-details-content .row {
	margin-left: -10px;
	margin-right: -10px;
}
.service-details-content .row > div {
	padding-left: 10px;
	padding-right: 10px;
}

/*******************************************************/
/****************** 11. Fact Counter *******************/
/*******************************************************/
.success-item {
	margin-bottom: 50px;
}
.success-item .count-text {
	font-size: 48px;
	line-height: 1;
	font-weight: 700;
	margin-bottom: 20px;
	display: inline-block;
}
.success-item .count-text.plus:after {
	content: '+';
}
.success-item .count-text.k:after {
	content: 'k';
}
.success-item p {
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 0;
}

.text-white .count-text {
	color: #f8f8f8;
}

/* Style Two */
.fact-counter-color .success-item .count-text {
	color: #231f20;
}

.fact-counter-color .success-item p {
	color: #ffb81a;
}

/*******************************************************/
/***************** 12. Feature Section *****************/
/*******************************************************/
.feature-item {
	padding: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: #f8f8f8;
	border-radius: 5px;
	position: relative;
	margin-bottom: 30px;
	border: 2px solid #e6ecf7;
}
.feature-item:hover {
	border-color: #f8f8f8;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 375px) {
	.feature-item {
		display: block;
	}
}
.feature-item .icon {
	margin-right: 25px;
}
.feature-item p {
	margin-bottom: 0;
}
.feature-item .learn-more {
	margin-top: 12px;
}
.feature-item .feature-btn {
	width: 45px;
	height: 45px;
	background: #f8f8f8;
	line-height: 45px;
	border-radius: 50%;
	text-align: center;
	color: #ffb81a;
	position: absolute;
	right: 30px;
	top: -20px;
	-webkit-box-shadow: 10px 0px 60px 0px rgba(186, 132, 16, 0.3);
	box-shadow: 10px 0px 60px 0px rgba(186, 132, 16, 0.3);
}

/*******************************************************/
/****************** 13. Work Progress ******************/
/*******************************************************/

/*******************************************************/
/***************** 15. Gallery Section *****************/
/*******************************************************/
.gallery-section:before {
	opacity: 1;
	height: 50%;
	background: #ffb81a;
}

.gallery-item {
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;
	height: 350px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
	.gallery-item {
		height: 450px;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
	.gallery-item {
		height: 400px;
	}
}
@media screen and (min-width: 1440px) {
	.gallery-item {
		height: 450px;
	}
}
.gallery-item:hover .gallery-content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
.gallery-item img {
	height: 100%;
	width: 100%;
}
.gallery-item .gallery-content {
	left: 20px;
	bottom: 20px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	width: calc(100% - 40px);
	padding: 22px 22px 10px;
	background: rgba(0, 0, 0, 0.8);
	-webkit-transform: translateY(calc(100% + 30px));
	-ms-transform: translateY(calc(100% + 30px));
	transform: translateY(calc(100% + 30px));
}
.gallery-item .gallery-content .category {
	font-size: 12px;
	margin-bottom: 5px;
	display: inline-block;
}

/* Gallery Style Two */
.gallery-section-a:before {
	opacity: 1;
	height: 70%;
	background: #f6f4e5;
}

.gallery-item.style-a {
	margin-bottom: 30px;
	border-radius: 5px;
}
.gallery-item.style-a .gallery-content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	padding: 25px 30px 25px 30px;
	background: #fcfcfc88;
}
.gallery-item.style-a .gallery-content h5 {
	padding-bottom: 15px;
	border-bottom: 1px solid #787c58;
}
.gallery-item.style-a .gallery-content .gallery-content-inner {
	width: 100%;
	height: 100%;
}

/* Gallery Style Four */
.gallery-item.style-b {
	margin-bottom: 30px;
}
.gallery-item.style-b .gallery-content {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	width: 100%;
	height: 100%;
	left: 0;
	top: 400;
	opacity: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	-webkit-transform: scale3d(0, 1, 1);
	transform: scale3d(0, 1, 1);
	background: rgba(222, 152, 0, 0.85);
}
.gallery-item.style-b:hover .gallery-content {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

/* Portfolio Details */
.portfolio-details-content h2 {
	font-size: 30px;
	margin-bottom: 15px;
}

.portfolio-details-content p {
	margin-bottom: 30px;
}

/*******************************************************/
/****************** 16. News Section *******************/
/*******************************************************/
.news-item {
	overflow: hidden;
	border-radius: 5px;
	margin-bottom: 30px;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
	background-color: #ffefcf;
}
.news-item img {
	width: 100%;
}

.news-content {
	padding: 22px 22px 2px;
	border-bottom: 1px solid #e3e3e3;
}

.post-meta-item {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 12px;
}
.post-meta-item li {
	font-size: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: 0 30px 5px 0;
}
.post-meta-item li:last-child {
	margin-right: 0;
}
.post-meta-item li i {
	margin-right: 10px;
	color: #ffb81a;
}
.post-meta-item li b {
	font-weight: 500;
}

.news-author {
	font-size: 15px;
	font-weight: 500;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding: 15px 22px;
}
.news-author img {
	width: 35px;
	height: 35px;
	background: #f8f8f8;
	line-height: 35px;
	border-radius: 50%;
	text-align: center;
	margin-right: 15px;
}

/* News Style Two */
.news-section {
	background-position: 0 100%;
	background-repeat: no-repeat;
}

/* News Style Three */
.news-item.style-a {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.news-item.style-a * {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.news-item.style-a .news-content {
	border-bottom: none;
	padding: 33px 30px 18px;
}
@media only screen and (max-width: 375px) {
	.news-item.style-a .news-content {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.news-item.style-a:hover {
	background: #ffb81a;
}
.news-item.style-a:hover * {
	color: #f8f8f8;
}
.news-item.style-a:hover p,
.news-item.style-a:hover .post-meta-item a {
	opacity: 0.5;
}

/* Blog Page */
.blog-page-area,
.blog-details-area {
	position: relative;
	z-index: 1;
}
.blog-page-area .container > .row,
.blog-details-area .container > .row {
	margin-left: -30px;
	margin-right: -30px;
}
.blog-page-area .container > .row > div,
.blog-details-area .container > .row > div {
	padding-left: 30px;
	padding-right: 30px;
}

.blog-details-content:after,
.blog-standard-content:after {
	content: '';
	top: 0;
	right: 0;
	z-index: -1;
	height: 100%;
	width: 110vw;
	background: #f8f8f8;
	position: absolute;
}

.blog-header,
.blog-footer {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.blog-header .social-style-a,
.blog-header .social-style-b,
.blog-footer .social-style-a,
.blog-footer .social-style-b {
	font-size: 14px;
	margin-bottom: 15px;
}
.blog-header .social-style-a span,
.blog-header .social-style-b span,
.blog-footer .social-style-a span,
.blog-footer .social-style-b span {
	font-size: 16px;
	margin-right: 15px;
}
.blog-header .social-style-a a:not(:hover),
.blog-footer .social-style-a a:not(:hover) {
	color: #8a8a8a;
}
.blog-header .social-style-b a,
.blog-footer .social-style-b a {
	width: 30px;
	height: 30px;
	opacity: 1;
	line-height: 30px;
	background: #f8f8f8;
}

.blog-footer .popular-tags b {
	font-weight: 500;
	color: #111111;
}

.blog-footer .popular-tags a:after {
	content: ',';
}

.blog-footer .popular-tags a:last-child:after {
	content: '';
}

.blog-standard-item .image,
.blog-details-content .image {
	margin-bottom: 35px;
}
.blog-standard-item .image img,
.blog-details-content .image img {
	width: 100%;
}

.blog-standard-item h3,
.blog-details-content h3 {
	font-size: 30px;
	font-weight: 500;
}
@media only screen and (max-width: 575px) {
	.blog-standard-item h3,
	.blog-details-content h3 {
		font-size: 25px;
	}
}
@media only screen and (max-width: 375px) {
	.blog-standard-item h3,
	.blog-details-content h3 {
		font-size: 20px;
	}
}

.blog-standard-item p,
.blog-details-content p {
	margin-bottom: 25px;
}

.blog-standard-item {
	margin-bottom: 55px;
}
.blog-standard-item .video-blog {
	position: relative;
}
.blog-standard-item .video-blog .video-play {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.blog-standard-item .video-blog .video-play i {
	color: #f8f8f8;
	font-size: 20px;
	width: 100px;
	height: 100px;
	background: #ffb81a;
	line-height: 100px;
	border-radius: 50%;
	text-align: center;
}
@media only screen and (max-width: 480px) {
	.blog-standard-item .video-blog .video-play i {
		width: 60px;
		height: 60px;
		line-height: 60px;
	}
}
.blog-standard-item.without-image {
	padding: 35px 25px;
	border: 1px solid #e9e9e9;
}
.blog-standard-item.quote-blog {
	z-index: 1;
	padding: 50px;
	position: relative;
	background: #ffb81a;
}
@media only screen and (max-width: 375px) {
	.blog-standard-item.quote-blog {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.blog-standard-item.quote-blog h3 {
	margin-bottom: 0;
}
.blog-standard-item.quote-blog h3 a {
	color: #111111;
}
.blog-standard-item.quote-blog:after {
	position: absolute;
	content: '\f10e';
	font-weight: 700;
	left: 50%;
	top: 50%;
	z-index: -1;
	color: #f8f8f8;
	opacity: 0.25;
	font-size: 100px;
	font-family: 'Font Awesome 5 Free';
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* Blog Details */
blockquote {
	font-size: 20px;
	line-height: 1.5;
	padding-left: 30px;
	margin: 30px 0 35px;
	color: #111111;
	border-left: 5px solid #ffb81a;
}
@media only screen and (max-width: 480px) {
	blockquote {
		font-size: 18px;
		padding-left: 20px;
	}
}
@media only screen and (max-width: 375px) {
	blockquote {
		font-size: 16px;
	}
}
blockquote .author {
	display: block;
	font-size: 16px;
	margin-top: 10px;
	font-weight: 500;
}

.next-prev-blog {
	z-index: 0;
	display: block;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 15px;
	padding: 30px 40px 20px;
	background: #e5eef6;
}
.next-prev-blog h4 {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
@media only screen and (max-width: 375px) {
	.next-prev-blog h4 {
		font-size: 18px;
	}
}
.next-prev-blog:hover {
	background: rgb(255, 184, 26, 0.9);
}
.next-prev-blog:hover h4 {
	color: #231f20;
}

/*******************************************************/
/********************* 17. Widgets *********************/
/*******************************************************/
.widget {
	background: #f8f8f8;
	padding: 35px 30px;
	margin-bottom: 55px;
}
@media only screen and (max-width: 375px) {
	.widget {
		padding-left: 20px;
		padding-right: 20px;
	}
}
.widget:last-child {
	margin-bottom: 0;
}
.widget .list-style-b li {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.widget .list-style-b li:last-child {
	margin-bottom: 0;
}
.widget .list-style-b li span {
	margin-left: 10px;
}

.widget-title {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 35px;
	position: relative;
}
.widget-title:after {
	content: '';
	width: 45px;
	height: 2px;
	left: 0;
	position: absolute;
	top: calc(100% + 10px);
	background: #ffb81a;
}
.widget-title:before {
	content: '';
	width: 5px;
	height: 2px;
	left: 10px;
	z-index: 1;
	background: #f8f8f8;
	position: absolute;
	top: calc(100% + 10px);
}

/* Call Action */
.widget-call-action {
	text-align: center;
	padding: 100px 50px;
	z-index: 1;
	position: relative;
}
.widget-call-action::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.85;
	background-color: #ffb81a;
}
@media only screen and (max-width: 1199px) {
	.widget-call-action {
		padding-left: 40px;
		padding-right: 40px;
	}
}
.widget-call-action h2 {
	color: #111111;
	font-size: 45px;
	font-weight: 500;
	margin-bottom: 35px;
}
@media only screen and (max-width: 375px) {
	.widget-call-action h2 {
		font-size: 35px;
	}
}
.widget-call-action p {
	color: #111111;
	margin-bottom: 35px;
}

/* Portfolio Info */
.widget-portfolio-info li {
	margin-bottom: 20px;
}
.widget-portfolio-info li h4 {
	margin-bottom: 5px;
}
.widget-portfolio-info li p {
	font-size: 18px;
	margin-bottom: 0;
}
@media only screen and (max-width: 375px) {
	.widget-portfolio-info li p {
		font-size: 16px;
	}
}
.widget-portfolio-info li:last-child {
	margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
	.widget-portfolio-info {
		padding-left: 30px;
		padding-right: 30px;
	}
}

/* Service Lise */
.widget-services {
	padding: 0;
}
.widget-services li {
	margin-bottom: 10px;
}
.widget-services li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 18px;
	padding: 20px 30px;
	border-radius: 5px;
	border: 2px solid #e6ecf7;
}
@media only screen and (max-width: 375px) {
	.widget-services li a {
		font-size: 16px;
	}
}
.widget-services li a:after {
	color: #8e959b;
	content: '\f061';
	font-size: 16px;
	font-weight: 700;
	margin-left: auto;
	font-family: 'Font Awesome 5 Free';
}
.widget-services li:hover a,
.widget-services li.active a {
	border-color: #ffb81a;
}

/*******************************************************/
/****************** 18. Client Logo ********************/
/*******************************************************/
.logo-carousel-wrap .slick-track {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}
.logo-carousel-wrap .slick-track .logo-item {
	padding-left: 15px;
	padding-right: 15px;
}
.logo-carousel-wrap .slick-track .logo-item img {
	display: inline-block;
}

/* Logo style two */
.logo-carousel-wrap.style-two {
	-webkit-transform: translateY(-120px);
	-ms-transform: translateY(-120px);
	transform: translateY(-120px);
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 991px) {
	.logo-carousel-wrap.style-two {
		-webkit-transform: translateY(-80px);
		-ms-transform: translateY(-80px);
		transform: translateY(-80px);
	}
}

/*******************************************************/
/************** 19. Testimonials Section ***************/
/*******************************************************/
.testimonial-wrap {
	margin-left: -15px;
	margin-right: -15px;
}

.testimonial-item {
	background: #f8f8f8;
	margin-left: 15px;
	margin-right: 15px;
	padding: 40px 45px 20px;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 480px) {
	.testimonial-item {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.author-description {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 22px;
}
.author-description img {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 80px;
	height: 80px;
	background: #f8f8f8;
	line-height: 80px;
	border-radius: 50%;
	text-align: center;
	margin-right: 22px;
}
@media only screen and (max-width: 375px) {
	.author-description img {
		width: 50px;
		height: 50px;
	}
}
.author-description .designation {
	margin-right: 20px;
}
.author-description .designation h5 {
	margin: 3px 0 6px;
}
.author-description i {
	opacity: 0.5;
	font-size: 50px;
	margin-left: auto;
	color: #ffb81a;
}
@media only screen and (max-width: 375px) {
	.author-description i {
		display: none;
	}
}

/* Testimonial Four */
.testimonial-four-active {
	margin-left: -15px;
	margin-right: -15px;
}
.testimonial-four-active.slick-initialized .slick-track {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.testimonial-four-active.slick-initialized .slick-slide {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.testimonial-four-active .slick-dots {
	margin-top: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media only screen and (max-width: 991px) {
	.testimonial-four-active .slick-dots {
		margin-top: 20px;
	}
}
.testimonial-four-active .slick-dots li {
	margin: 5px;
	width: 30px;
	height: 30px;
	overflow: hidden;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	border-radius: 50%;
	border: 1px solid rgba(92, 69, 0, 0.2);
}
.testimonial-four-active .slick-dots li:before {
	position: absolute;
	content: '';
	width: 10px;
	height: 10px;
	left: 9px;
	top: 9px;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 50%;
	background: #ffb81a;
}
.testimonial-four-active .slick-dots li.slick-active {
	border-color: #ffb81a;
}
.testimonial-four-active .slick-dots li.slick-active:before {
	opacity: 1;
}
.testimonial-four-active .slick-dots li button {
	text-indent: 100px;
	background-color: transparent;
}

.testimonial-four-item {
	margin: 15px;
	float: none;
	height: auto;
	-webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

.testimonial-four-content {
	padding: 50px 40px 40px;
}
@media only screen and (max-width: 375px) {
	.testimonial-four-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.testimonial-four-author {
	margin-top: auto;
	position: relative;
	padding: 0 40px 0 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media only screen and (max-width: 375px) {
	.testimonial-four-author {
		display: block;
		padding-left: 25px;
		padding-right: 25px;
	}
}
.testimonial-four-author:after {
	top: -15px;
	right: 40px;
	opacity: 0.1;
	color: #5c4200;
	font-weight: 600;
	content: '\f10e';
	font-size: 75px;
	position: absolute;
	font-family: 'Font Awesome 5 Free';
}
.testimonial-four-author img {
	width: 50%;
}
.testimonial-four-author h4 {
	margin-bottom: 0;
}
.testimonial-four-author span {
	font-size: 14px;
	font-weight: 600;
}

.testimonial-four-author-designation {
	padding: 45px 0 15px 10px;
}
@media only screen and (max-width: 375px) {
	.testimonial-four-author-designation {
		padding: 20px 0 45px;
	}
}

/*******************************************************/
/****************** 20. Team Members *******************/
/*******************************************************/
.team-member {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	text-align: center;
	margin-bottom: 30px;
}
.team-member .image img {
	border-radius: 5px;
}
.team-member:hover .member-designation {
	color: #f8f8f8;
	background: #ffb81a;
}
.team-member:hover .member-designation h5 a {
	color: #f8f8f8;
}
.team-member .member-designation {
	padding: 20px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-top: 12px;
	border-radius: 5px;
	background: #e5eef6;
}
.team-member .member-designation h5 {
	margin-bottom: 8px;
}

/* Team Three */
.team-member-three {
	text-align: center;
}
.team-member-three .image img {
	width: 100%;
}
.team-member-three .member-designation {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 28px;
	margin-left: 30px;
	margin-right: 30px;
}
@media only screen and (max-width: 767px) {
	.team-member-three .member-designation {
		margin-left: 15px;
		margin-right: 15px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.team-member-three .member-designation h3 {
	margin-bottom: 0;
}
.team-member-three .member-designation h3 a :hover {
	color: #ffb81a;
}
@media only screen and (max-width: 767px) {
	.team-member-three .member-designation h3 {
		font-size: 20px;
	}
}
.team-member-three .member-designation .social-style-a {
	opacity: 0;
	display: block;
	margin-top: 10px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.team-member-three .member-designation .social-style-a a {
	margin-right: 30px;
}
.team-member-three .member-designation .social-style-a a:last-child {
	margin-right: 0;
}
.team-member-three:hover .member-designation {
	background: #f8f8f8;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-box-shadow: -11px 12px 33px 0px rgba(191, 178, 32, 0.08);
	box-shadow: -11px 12px 33px 0px rgba(191, 165, 32, 0.08);
}
.team-member-three:hover .social-style-a {
	opacity: 1;
}

/* Team Profile */
.team-profile-content .name {
	margin-bottom: 5px;
}
@media only screen and (max-width: 480px) {
	.team-profile-content .name {
		font-size: 30px;
	}
}
@media only screen and (max-width: 375px) {
	.team-profile-content .name {
		font-size: 25px;
	}
}

.team-profile-content .designation {
	font-size: 22px;
	display: inline-block;
	margin-bottom: 35px;
}
@media only screen and (max-width: 375px) {
	.team-profile-content .designation {
		font-size: 18px;
	}
}

.team-profile-content .list-style-c {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.team-profile-content .list-style-c li {
	width: 45%;
	margin-bottom: 15px;
}
@media only screen and (max-width: 480px) {
	.team-profile-content .list-style-c li {
		width: 100%;
	}
}

.team-profile-content .social-style-b a {
	opacity: 1;
	margin-right: 20px;
	color: #111111;
	background: #e5eef6;
}
.team-profile-content .social-style-b a:hover {
	color: #f8f8f8;
	background: #ffb81a;
}

/*******************************************************/
/**************** 21. Subscribe Section ****************/
/*******************************************************/

/*******************************************************/
/***************** 22. Call To Action ******************/
/*******************************************************/
.call-to-action .section-title h2 {
	font-size: 30px;
}
@media only screen and (max-width: 375px) {
	.call-to-action .section-title h2 {
		font-size: 25px;
	}
}

/* Call Action Two */
@media only screen and (min-width: 1501px) {
	.call-to-action-two .section-title h2 {
		font-size: 48px;
	}
}

/* Call Action Three */
.call-action-three {
	z-index: 1;
	position: relative;
}
.call-action-three::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.95;
	background-color: #f43336;
}
.call-action-three h2 {
	font-size: 30px;
	text-transform: uppercase;
}
@media only screen and (max-width: 375px) {
	.call-action-three h2 {
		font-size: 25px;
	}
}

/* Footer Call to Action */
.footer-call-action-inner {
	padding-left: 60px;
	padding-right: 60px;
}
@media only screen and (max-width: 480px) {
	.footer-call-action-inner {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.footer-call-action-content h2 {
	font-size: 30px;
}
@media only screen and (max-width: 375px) {
	.footer-call-action-content h2 {
		font-size: 25px;
	}
}

/* Call Action Four */
.call-action-four {
	background-size: cover;
}
@media only screen and (max-width: 575px) {
	.call-action-four {
		padding-left: 35px;
		padding-right: 35px;
	}
}
@media only screen and (max-width: 375px) {
	.call-action-four {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.call-action-four .section-title {
	max-width: 700px;
}
.call-action-four .right-image {
	z-index: -1;
	right: -10px;
	bottom: -40px;
	max-width: 40%;
	position: absolute;
}

/*******************************************************/
/**************** 23. Contact Section ******************/
/*******************************************************/
.contact-section-inner {
	-webkit-transform: translateY(-100px);
	-ms-transform: translateY(-100px);
	transform: translateY(-100px);
	position: relative;
	z-index: 1;
}
@media only screen and (max-width: 480px) {
	.contact-section-inner {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.contact-section-inner > .row {
	margin-left: -22.5px;
	margin-right: -22.5px;
}
.contact-section-inner > .row > div {
	padding-left: 22.5px;
	padding-right: 22.5px;
}

.contact-image-number {
	position: relative;
	height: 100%;
	overflow: hidden;
	min-height: 400px;
	border-radius: 5px;
}
.contact-image-number .contact-number {
	position: absolute;
	left: 0;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50%;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	text-align: center;
	padding-bottom: 30px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		from(#f0f0f0),
		to(transparent)
	);
	background-image: -webkit-linear-gradient(bottom, #f0f0f0, transparent);
	background-image: -o-linear-gradient(bottom, #f0f0f0, transparent);
	background-image: linear-gradient(to top, #f0f0f0, transparent);
}
.contact-image-number .contact-number i {
	margin-right: 15px;
	width: 40px;
	height: 40px;
	background: #f8f8f8;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
	color: #f0f0f0;
}
.contact-image-number .contact-number a {
	color: #f8f8f8;
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 7px;
}
@media only screen and (max-width: 375px) {
	.contact-image-number .contact-number a {
		font-size: 16px;
	}
}
.contact-image-number.style-two {
	padding: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media only screen and (max-width: 480px) {
	.contact-image-number.style-two {
		display: block;
		padding-left: 15px;
		padding-right: 15px;
	}
	.contact-image-number.style-two h5 a {
		word-break: break-all;
	}
}
.contact-image-number.style-two:before {
	opacity: 0.9;
	background-color: #f0f0f0;
}
.contact-image-number.style-two h3 {
	font-size: 30px;
}
@media only screen and (max-width: 480px) {
	.contact-image-number.style-two h3 {
		font-size: 25px;
	}
}
.contact-image-number .contact-info li {
	margin-top: 35px;
}
.contact-image-number .contact-info li i {
	color: #6b6b6b;
	background: rgba(90, 90, 90, 0.25);
}
.contact-image-number .contact-info li span {
	opacity: 0.7;
}

/* Contact Two */
.contact-two .contact-section-inner {
	margin-top: -50px;
	-webkit-transform: translateY(130px);
	-ms-transform: translateY(130px);
	transform: translateY(130px);
	-webkit-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 375px) {
	.contact-two .contact-section-inner {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (max-width: 991px) {
	.contact-two .contact-section-inner {
		-webkit-transform: translateY(100px);
		-ms-transform: translateY(100px);
		transform: translateY(100px);
	}
}

/* Contact Three */
.contact-section-three .container {
	max-width: 1380px;
}

@media only screen and (max-width: 375px) {
	.contact-form-three {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.contact-form-three button {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
}

/* Contact Page */
.contact-info-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 55px 0 25px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 10px solid #f1f1f1;
}

.contact-info-item {
	width: 33.33%;
	text-align: center;
	margin-bottom: 30px;
	padding-left: 50px;
	padding-right: 50px;
	border-right: 5px solid #f1f1f1;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
	.contact-info-item {
		width: 50%;
	}
}
@media only screen and (max-width: 767px) {
	.contact-info-item {
		width: 50%;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (max-width: 480px) {
	.contact-info-item {
		width: 100%;
		border-right: none;
	}
}
.contact-info-item:last-child {
	border-right: none;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
	.contact-info-item:nth-child(2) {
		border-right: none;
	}
}
@media only screen and (max-width: 767px) {
	.contact-info-item:nth-child(2) {
		border-right: none;
	}
}
.contact-info-item i {
	font-size: 40px;
	margin-bottom: 15px;
	display: inline-block;
	color: #ffb81a;
}
.contact-info-item p {
	color: #8a8a8a;
	font-size: 18px;
	font-weight: 500;
	max-width: 235px;
	margin-left: auto;
	margin-right: auto;
}
.contact-info-item p a {
	color: #8a8a8a;
}

.contact-form-left {
	z-index: 1;
	position: relative;
	min-height: 400px;
}
.contact-form-left::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.6;
	background-color: #ffb81a;
}
.contact-form-left h2 {
	width: 80%;
	left: 10%;
	color: #f8f8f8;
	bottom: 40px;
	font-size: 40px;
	padding-left: 30px;
	position: absolute;
	border-left: 5px solid #f8f8f8;
}
@media only screen and (max-width: 375px) {
	.contact-form-left h2 {
		font-size: 35px;
	}
}

.contact-page-map {
	margin-bottom: -10px;
}
@media only screen and (max-width: 1199px) {
	.contact-page-map iframe {
		height: 500px;
	}
}
@media only screen and (max-width: 767px) {
	.contact-page-map iframe {
		height: 400px;
	}
}

/*******************************************************/
/******************** 24. Shop Page ********************/
/*******************************************************/

/*******************************************************/
/****************** 25. FAQs Section ******************/
/*******************************************************/
.faq-accordion .card {
	border: none;
	border-radius: 0;
	margin-bottom: 20px;
	-webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
}

.faq-accordion .card-header {
	border: none;
	color: #000f5c;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 20px;
	font-weight: 500;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 25px 40px;
	background: transparent;
	font-family: 'Circular Std';
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media only screen and (max-width: 480px) {
	.faq-accordion .card-header {
		padding-left: 25px;
		padding-right: 25px;
	}
}
@media only screen and (max-width: 375px) {
	.faq-accordion .card-header {
		font-size: 18px;
	}
}

.faq-accordion .card-body {
	margin-top: -15px;
	padding: 0 40px 10px;
}
@media only screen and (max-width: 480px) {
	.faq-accordion .card-body {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.faq-accordion .toggle-btn {
	width: 30px;
	height: 30px;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-left: 8px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
@media only screen and (max-width: 375px) {
	.faq-accordion .toggle-btn {
		width: 20px;
		height: 20px;
	}
}
.faq-accordion .toggle-btn:after,
.faq-accordion .toggle-btn:before {
	position: absolute;
	content: '';
	left: 50%;
	top: 50%;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: #000f5c;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.faq-accordion .toggle-btn:before {
	width: 2px;
	z-index: 1;
	height: 100%;
}
.faq-accordion .toggle-btn:after {
	height: 2px;
	width: 100%;
}

.faq-triangle {
	top: 20%;
	left: 10%;
	position: absolute;
	-webkit-animation: shapeAnimationFive 20s linear infinite;
	animation: shapeAnimationFive 20s linear infinite;
}

.faq-box-shape {
	top: 20%;
	right: 20%;
	position: absolute;
	width: 10px;
	height: 10px;
	background: #ff7a41;
	-webkit-animation: shapeAnimationThree 20s linear infinite;
	animation: shapeAnimationThree 20s linear infinite;
}

/*******************************************************/
/******************* 26. 404 Error *********************/
/*******************************************************/
.error-content {
	max-width: 770px;
	margin-left: auto;
	margin-right: auto;
}
.error-content h2 {
	font-size: 48px;
	margin: 50px 0 30px;
}
@media only screen and (max-width: 991px) {
	.error-content h2 {
		font-size: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.error-content h2 {
		font-size: 35px;
	}
}
@media only screen and (max-width: 480px) {
	.error-content h2 {
		font-size: 30px;
	}
}
.error-content .theme-btn {
	padding-left: 45px;
	padding-right: 45px;
}

/*******************************************************/
/******************* 27. Main Slider *******************/
/*******************************************************/
.main-slider-wrap .image {
	width: 100%;
	height: 100%;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.main-slider-wrap .image img {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: none;
	min-width: 100%;
	min-height: 100%;
}
.main-slider-wrap .image:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#1f1f03),
		to(rgba(31, 30, 3, 0.5))
	);
	background: -webkit-linear-gradient(
		left,
		#1d1f03 0%,
		rgba(30, 31, 3, 0.5) 100%
	);
	background: -o-linear-gradient(left, #03091f 0%, rgba(3, 9, 31, 0.5) 100%);
	background: linear-gradient(90deg, #03091f 0%, rgba(3, 9, 31, 0.5) 100%);
}

.main-slider-wrap .slider-caption {
	z-index: 2;
	min-height: 828px;
	position: relative;
	padding-top: 92px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: 100px;
}
@media only screen and (max-width: 1500px) {
	.main-slider-wrap .slider-caption {
		min-height: 700px;
	}
}
@media only screen and (max-width: 991px) {
	.main-slider-wrap .slider-caption {
		min-height: 600px;
	}
}

.main-slider-wrap .slick-arrow {
	top: 50%;
	z-index: 2;
	opacity: 0.2;
	font-size: 22px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: #f43336;
	position: absolute;
	width: 60px;
	height: 60px;
	background: #f8f8f8;
	line-height: 60px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media only screen and (max-width: 575px) {
	.main-slider-wrap .slick-arrow {
		width: 40px;
		height: 40px;
		font-size: 18px;
		line-height: 40px;
	}
}
.main-slider-wrap .slick-arrow i {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: #f43336;
}
.main-slider-wrap .slick-arrow.prev {
	left: 200px;
}
@media only screen and (max-width: 1500px) {
	.main-slider-wrap .slick-arrow.prev {
		left: 25px;
	}
}
.main-slider-wrap .slick-arrow.next {
	right: 200px;
}
@media only screen and (max-width: 1500px) {
	.main-slider-wrap .slick-arrow.next {
		right: 25px;
	}
}
.main-slider-wrap .slick-arrow:hover,
.main-slider-wrap .slick-arrow:focus {
	background: #f43336;
}
.main-slider-wrap .slick-arrow:hover i,
.main-slider-wrap .slick-arrow:focus i {
	color: #f8f8f8;
}

.main-slider-wrap:hover .slick-arrow {
	opacity: 1;
}

.main-slider-content {
	max-width: 610px;
}
.main-slider-content .sub-title {
	margin-bottom: 10px;
	display: inline-block;
	text-transform: uppercase;
}
.main-slider-content h1 {
	margin-bottom: 20px;
}
@media only screen and (max-width: 1500px) {
	.main-slider-content h1 {
		font-size: 60px;
	}
}
@media only screen and (max-width: 991px) {
	.main-slider-content h1 {
		font-size: 50px;
	}
}
@media only screen and (max-width: 767px) {
	.main-slider-content h1 {
		font-size: 45px;
	}
}
@media only screen and (max-width: 575px) {
	.main-slider-content h1 {
		font-size: 40px;
	}
}
@media only screen and (max-width: 480px) {
	.main-slider-content h1 {
		font-size: 35px;
	}
}
@media only screen and (max-width: 375px) {
	.main-slider-content h1 {
		font-size: 30px;
	}
}
.main-slider-content p {
	font-size: 18px;
	max-width: 555px;
}
@media only screen and (max-width: 480px) {
	.main-slider-content p {
		font-size: 16px;
	}
}
.main-slider-content .slider-btns {
	padding-top: 20px;
}
.main-slider-content .slider-btns .theme-btn {
	margin-top: 15px;
}
.main-slider-content .slider-btns .theme-btn:not(:last-child) {
	margin-right: 30px;
}
.main-slider-content * {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.slick-active * {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.slick-active h1 {
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
}

.slick-active p {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.slick-active .slider-btns {
	-webkit-animation-delay: 0.75s;
	animation-delay: 0.75s;
}

/*******************************************************/
/***************** 29. Actions Section *****************/
/*******************************************************/
.actions-section {
	z-index: 1;
	position: relative;
}
.actions-section:before {
	left: 10%;
	top: 50%;
	content: '';
	opacity: 0.2;
	z-index: -1;
	width: 500px;
	height: 300px;
	position: absolute;
	-webkit-filter: blur(150px);
	filter: blur(150px);
	background: #20c997;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.actions-section:after {
	left: 0;
	top: 0;
	content: '';
	z-index: -2;
	width: 100%;
	height: 130%;
	position: absolute;
	background: #040b11;
}

.actions-active {
	margin-left: -15px;
	margin-right: -15px;
}
.actions-active .action-item {
	margin-left: 15px;
	margin-right: 15px;
}

.action-item {
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 30px;
	background: #1b2429;
	border: 1px solid rgba(255, 255, 255, 0.15);
}
.action-item .image {
	position: relative;
}
.action-item .image img {
	width: 100%;
	border-radius: 5px;
}
.action-item .image .count-down {
	left: 15px;
	bottom: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: #f8f8f8;
	border-radius: 5px;
	position: absolute;
	padding-top: 7px;
	padding-bottom: 7px;
	width: calc(100% - 30px);
}
.action-item .image .count-down li {
	width: 25%;
	line-height: 1;
	font-size: 10px;
	text-align: center;
	color: #454545;
}
.action-item .image .count-down li span {
	display: block;
	font-size: 16px;
	font-weight: 700;
	color: #111111;
}
.action-item .image .count-down li:not(:last-child) {
	border-right: 1px solid rgba(7, 1, 47, 0.1);
}
.action-item .content {
	padding: 20px 15px 5px;
}
.action-item .content .bid-dots {
	margin-bottom: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.action-item .content .bid-dots .bid {
	font-size: 14px;
	font-weight: 500;
	font-family: 'Urbanist', sans-serif;
}
.action-item .content .bid-dots .bid b {
	font-weight: 700;
}
.action-item .content .bid-dots .dots {
	width: 30px;
	height: 16px;
	border-radius: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.1);
}
.action-item .content .bid-dots .dots span {
	width: 3px;
	height: 3px;
	margin: 1.5px;
	background: #f8f8f8;
	border-radius: 50%;
}
.action-item .content .author-wish {
	margin-top: 15px;
	padding-top: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-top: 1px dashed rgba(255, 255, 255, 0.2);
}
.action-item .content .author-wish .author {
	line-height: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.action-item .content .author-wish .author img {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	margin-right: 10px;
}
.action-item .content .author-wish .author h6 {
	font-size: 14px;
	margin-bottom: 0;
	font-weight: 700;
	letter-spacing: -0.03em;
}
.action-item .content .author-wish .author span {
	font-size: 12px;
	color: #a0a4a8;
}
.action-item .content .author-wish .wish {
	font-size: 14px;
}
.action-item .content .author-wish .wish i {
	margin-right: 5px;
}

.collection-section {
	position: relative;
	z-index: 1;
}
.collection-section:after,
.collection-section:before {
	content: '';
	z-index: -1;
	opacity: 0.1;
	position: absolute;
	width: 600px;
	height: 400px;
	-webkit-filter: blur(100px);
	filter: blur(100px);
	background: #20c997;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.collection-section:before {
	right: 0;
	top: 0;
}
.collection-section:after {
	left: 0;
	top: 50%;
}

.collection-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
}
.collection-filter li {
	z-index: 1;
	cursor: pointer;
	font-weight: 600;
	position: relative;
	padding: 5px 18px;
	border-radius: 2px;
	background: #1b2429;
	margin-right: 20px;
	margin-bottom: 10px;
	font-family: 'Urbanist', sans-serif;
	border: 1.5px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 1199px) {
	.collection-filter li {
		margin-right: 10px;
	}
}
@media only screen and (max-width: 375px) {
	.collection-filter li {
		font-size: 14px;
	}
}
.collection-filter li:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	z-index: -1;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 2px;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(-21.46%, #a146e8),
		color-stop(36.39%, #6c63d0),
		to(#387db8)
	);
	background: -webkit-linear-gradient(
		left,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
	background: -o-linear-gradient(
		left,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
	background: linear-gradient(
		90deg,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
}
.collection-filter li:hover:before,
.collection-filter li.current:before {
	opacity: 1;
}

/* Cagegory Collection */
.collection-category-area:after,
.collection-category-area:before {
	content: '';
	z-index: -1;
	opacity: 0.1;
	position: absolute;
	width: 600px;
	height: 400px;
	-webkit-filter: blur(100px);
	filter: blur(100px);
	background: #20c997;
}

.collection-category-area:before {
	left: 0;
	bottom: -10%;
}

.collection-category-area:after {
	top: 10%;
	right: 0;
}

.collection-category-active {
	margin-left: -15px;
	margin-right: -15px;
}
.collection-category-active .collection-category-item {
	margin-left: 15px;
	margin-right: 15px;
}
.collection-category-active .slick-arrow {
	border: none;
	position: absolute;
	bottom: calc(50% + 33px);
}
.collection-category-active .slick-arrow.prev {
	left: 15px;
}
.collection-category-active .slick-arrow.next {
	right: 15px;
}
.collection-category-active .slick-arrow:before {
	opacity: 1;
}

.collection-category-item {
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 30px;
	background: #1b2429;
	border: 1px solid rgba(255, 255, 255, 0.15);
}
.collection-category-item .category-images .row {
	margin-left: -5px;
	margin-right: -5px;
}
.collection-category-item .category-images .row > div {
	padding-left: 5px;
	padding-right: 5px;
}
.collection-category-item .category-images a {
	width: 100%;
	margin-bottom: 10px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.collection-category-item .category-images a img {
	width: 100%;
	border-radius: 5px;
}
.collection-category-item .title-dots {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 20px;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.collection-category-item .title-dots h4 {
	margin-bottom: 8px;
}
.collection-category-item .title-dots .dots {
	width: 26px;
	height: 34px;
	border-radius: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.2);
}
.collection-category-item .title-dots .dots span {
	width: 3px;
	height: 3px;
	margin: 1.5px 3px;
	background: #f8f8f8;
	border-radius: 50%;
}

/*******************************************************/
/***************** 30. Sellers Section *****************/
/*******************************************************/
.sellers-section {
	overflow: hidden;
}
.sellers-section .container:before {
	top: 0;
	left: 50%;
	content: '';
	z-index: -1;
	opacity: 0.1;
	position: absolute;
	width: 600px;
	height: 400px;
	-webkit-filter: blur(100px);
	filter: blur(100px);
	background: #20c997;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
}
.sellers-section .container:after {
	left: 50%;
	top: 50%;
	content: '';
	z-index: -2;
	position: absolute;
	background: #0f1625;
	width: calc(100% + 230px);
	height: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.sellers-active {
	margin-left: -15px;
	margin-right: -15px;
}
.sellers-active .seller-item {
	margin-left: 15px;
	margin-right: 15px;
}

.seller-item {
	padding: 20px;
	position: relative;
	border-radius: 5px;
	margin-bottom: 30px;
	background: #1b2429;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, 0.15);
}
.seller-item .number {
	top: 20px;
	right: 20px;
	color: #a0a4a8;
	font-size: 14px;
	position: absolute;
	font-family: 'Urbanist', sans-serif;
	width: 30px;
	height: 30px;
	background: rgba(196, 196, 196, 0.1);
	line-height: 30px;
	border-radius: 50%;
	text-align: center;
}
.seller-item .image {
	position: relative;
	display: inline-block;
}
.seller-item .image img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}
.seller-item .image .check {
	bottom: 0;
	right: 15%;
	font-size: 8px;
	position: absolute;
	width: 20px;
	height: 20px;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(-21.46%, #a146e8),
		color-stop(36.39%, #6c63d0),
		to(#387db8)
	);
	background: -webkit-linear-gradient(
		left,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
	background: -o-linear-gradient(
		left,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
	background: linear-gradient(
		90deg,
		#a146e8 -21.46%,
		#6c63d0 36.39%,
		#387db8 100%
	);
	line-height: 20px;
	border-radius: 50%;
	text-align: center;
}
.seller-item h5 {
	margin: 15px 0 0;
}
.seller-item .items {
	color: #cacccf;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Urbanist', sans-serif;
}

/*******************************************************/
/**************** 31. Main Footer Style ****************/
/*******************************************************/
.footer-widget-area {
	background-color: #c58d15;
}

.footer-logo {
	margin-top: -10px;
}

.footer-widget {
	margin-bottom: 50px;
}

.footer-title {
	margin-bottom: 28px;
}

.newsletter-widget form {
	padding: 7px;
	max-width: 310px;
	margin-top: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 35px;
	border: 1px solid #655c3a;
}
.newsletter-widget form input {
	color: #f8f8f8;
	height: auto;
	border: none;
	background: transparent;
	padding: 5px 5px 5px 20px;
}
.newsletter-widget form input::-webkit-input-placeholder {
	color: #f8f8f8;
}
.newsletter-widget form input:-ms-input-placeholder {
	color: #f8f8f8;
}
.newsletter-widget form input::-ms-input-placeholder {
	color: #f8f8f8;
}
.newsletter-widget form input::placeholder {
	color: #f8f8f8;
}
.newsletter-widget form button {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 45px;
	height: 45px;
	background: #efef41;
	line-height: 45px;
	border-radius: 50%;
	text-align: center;
}

@media only screen and (max-width: 1199px) {
	.newsletter-widget p br {
		display: none;
	}
}

.contact-info li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.contact-info li i {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: #ced1d5;
	margin: 5px 12px 0 0;
	width: 40px;
	height: 40px;
	background: #505222;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
}

.widget-news-item {
	max-width: 300px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 25px;
}
.widget-news-item:last-child {
	margin-bottom: 0;
}
.widget-news-item img {
	max-width: 75px;
	margin-right: 22px;
}

.widget-news-content h6,
.widget-news-content h5 {
	margin-bottom: 5px;
}

.footer-index {
	z-index: 100;
}

.footer-top .contact-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
	.footer-top .contact-info {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}
.footer-top .contact-info li {
	width: 33.33%;
	padding-top: 15px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-right: 1px solid #524b22;
}
.footer-top .contact-info li:first-child {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.footer-top .contact-info li:last-child {
	margin-right: 0;
	border-right: none;
}
@media only screen and (max-width: 767px) {
	.footer-top .contact-info li {
		width: auto;
		margin-right: 25px;
		border-right: none;
	}
}
.footer-top .contact-info li i {
	color: #f8f8f8;
	background: #ffb81a;
	border-color: #f8f8f8;
}

/** Footer Copyright **/
.copyright-inner {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 18px 0 3px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.copyright-inner p {
	opacity: 0.7;
	font-size: 14px;
	margin-bottom: 10px;
}

.main-footer {
	z-index: 50;
	position: relative;
}

.main-footer .list-style-c li:before {
	color: #f8f8f8;
	font-size: 14px;
	content: '\f054';
}

/* Footer Four */
.footer-sign-up {
	-webkit-transform: translateY(-90px);
	-ms-transform: translateY(-90px);
	transform: translateY(-90px);
}
@media only screen and (max-width: 767px) {
	.footer-sign-up .footer-signup-inner {
		padding-left: 25px;
		padding-right: 25px;
	}
}
@media only screen and (max-width: 375px) {
	.footer-sign-up .footer-signup-inner {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.footer-sign-up .container {
	max-width: 1330px;
}
.footer-sign-up .sign-in-form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: #f8f8f8;
	position: relative;
	border-radius: 5px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 15px 10px 0;
}
@media only screen and (max-width: 480px) {
	.footer-sign-up .sign-in-form {
		padding: 5px;
	}
}
.footer-sign-up .sign-in-form input {
	border: none;
	background: transparent;
}
@media only screen and (max-width: 375px) {
	.footer-sign-up .sign-in-form input {
		padding: 10px 0 10px 10px;
	}
}
.footer-sign-up .sign-in-form input::-webkit-input-placeholder {
	font-weight: 500;
	color: #111111;
}
.footer-sign-up .sign-in-form input:-ms-input-placeholder {
	font-weight: 500;
	color: #111111;
}
.footer-sign-up .sign-in-form input::-ms-input-placeholder {
	font-weight: 500;
	color: #111111;
}
.footer-sign-up .sign-in-form input::placeholder {
	font-weight: 500;
	color: #111111;
}
.footer-sign-up .sign-in-form button {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}
@media only screen and (max-width: 375px) {
	.footer-sign-up .sign-in-form button {
		padding: 10px 15px;
	}
}

.footer-style .social-style-b a {
	opacity: 1;
	color: #454545;
	background: #f8f8f8;
}
.footer-style .social-style-b a:hover {
	color: #f8f8f8;
	background: #fdd179;
}

.footer-style .list-style-c li:before {
	color: #454545;
}

.footer-style .list-style-b i {
	color: #e29a00;
}

.fab {
	color: #ffb81a;
}
